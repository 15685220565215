import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  PhoneIcon,
  ChatBubbleLeftRightIcon,
  RadioIcon,
  EnvelopeIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline';

function ViewCommunication() {
  const { id } = useParams();
  const [communication, setCommunication] = useState({
    id: id,
    timestamp: '2024-02-20 10:30 AM',
    type: 'Radio',
    from: 'Engine 5',
    to: 'Command 1',
    priority: 'High',
    status: 'Received',
    subject: 'Situation Update',
    incident: 'Structure Fire - 123 Main St',
    message: 'Fire contained to the first floor. Ventilation in progress. Request additional water supply.',
    attachments: [
      { id: 1, name: 'scene-photo.jpg', type: 'image', size: '2.4 MB' },
      { id: 2, name: 'incident-report.pdf', type: 'document', size: '1.1 MB' }
    ],
    thread: [
      {
        id: 1,
        timestamp: '2024-02-20 10:28 AM',
        from: 'Command 1',
        message: 'Copy that. Additional water supply en route.',
        status: 'Received'
      },
      {
        id: 2,
        timestamp: '2024-02-20 10:25 AM',
        from: 'Engine 5',
        message: 'Initial size-up complete. Heavy smoke showing from alpha side.',
        status: 'Received'
      }
    ]
  });

  const getTypeIcon = (type) => {
    switch (type) {
      case 'Radio':
        return <RadioIcon className="type-icon radio" />;
      case 'Phone':
        return <PhoneIcon className="type-icon phone" />;
      case 'Message':
        return <ChatBubbleLeftRightIcon className="type-icon message" />;
      case 'Email':
        return <EnvelopeIcon className="type-icon email" />;
      default:
        return null;
    }
  };

  return (
    <div className="view-communication-page">
      <div className="page-header">
        <div className="header-title">
          <Link to="/communications" className="back-link">
            <ArrowLeftIcon className="back-icon" />
            Back to Communications
          </Link>
          <h1>{communication.subject}</h1>
        </div>
        <div className="header-actions">
          <Link to={`/communications/reply/${id}`} className="reply-btn">
            Reply
          </Link>
        </div>
      </div>

      <div className="communication-detail-container">
        <div className="message-header">
          <div className="message-meta">
            <div className="type-container">
              {getTypeIcon(communication.type)}
              <span className="type-label">{communication.type}</span>
            </div>
            <span className="priority-badge-large">
              {communication.priority} Priority
            </span>
            <span className="status-badge-large">
              {communication.status}
            </span>
          </div>
          
          <div className="message-info">
            <div className="info-row">
              <span className="label">From:</span>
              <span className="value">{communication.from}</span>
            </div>
            <div className="info-row">
              <span className="label">To:</span>
              <span className="value">{communication.to}</span>
            </div>
            <div className="info-row">
              <span className="label">Time:</span>
              <span className="value">{communication.timestamp}</span>
            </div>
            <div className="info-row">
              <span className="label">Incident:</span>
              <span className="value">{communication.incident}</span>
            </div>
          </div>
        </div>

        <div className="message-content">
          <div className="message-body">
            {communication.message}
          </div>

          {communication.attachments.length > 0 && (
            <div className="attachments-section">
              <h3>Attachments</h3>
              <div className="attachments-list">
                {communication.attachments.map(attachment => (
                  <div key={attachment.id} className="attachment-item">
                    <span className="attachment-name">{attachment.name}</span>
                    <span className="attachment-size">{attachment.size}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="message-thread">
          <h3>Communication Thread</h3>
          <div className="thread-messages">
            {communication.thread.map(message => (
              <div key={message.id} className="thread-message">
                <div className="thread-message-header">
                  <span className="thread-message-from">{message.from}</span>
                  <span className="thread-message-time">{message.timestamp}</span>
                </div>
                <div className="thread-message-content">
                  {message.message}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCommunication; 