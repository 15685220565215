import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  ArrowLeftIcon,
  PaperClipIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

function ReplyCommunication() {
  const { id } = useParams();
  const [replyData, setReplyData] = useState({
    to: 'Engine 5',
    subject: 'Re: Situation Update',
    message: '',
    priority: 'Medium',
    type: 'Radio',
    attachments: []
  });

  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle reply submission
    console.log('Reply submitted:', replyData);
  };

  return (
    <div className="reply-communication-page">
      <div className="page-header">
        <div className="header-title">
          <Link to="/communications" className="back-link">
            <ArrowLeftIcon className="back-icon" />
            Back to Communications
          </Link>
          <h1>Reply to Communication</h1>
        </div>
      </div>

      <div className="reply-form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-group">
              <label>To</label>
              <input
                type="text"
                value={replyData.to}
                onChange={(e) => setReplyData({...replyData, to: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Subject</label>
              <input
                type="text"
                value={replyData.subject}
                onChange={(e) => setReplyData({...replyData, subject: e.target.value})}
              />
            </div>

            <div className="form-group">
              <label>Type</label>
              <select
                value={replyData.type}
                onChange={(e) => setReplyData({...replyData, type: e.target.value})}
              >
                <option value="Radio">Radio</option>
                <option value="Phone">Phone</option>
                <option value="Message">Message</option>
                <option value="Email">Email</option>
              </select>
            </div>

            <div className="form-group">
              <label>Priority</label>
              <select
                value={replyData.priority}
                onChange={(e) => setReplyData({...replyData, priority: e.target.value})}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Message</label>
            <textarea
              value={replyData.message}
              onChange={(e) => setReplyData({...replyData, message: e.target.value})}
              rows={6}
              placeholder="Type your message here..."
            />
          </div>

          <div className="attachments-section">
            <label>Attachments</label>
            <div className="file-upload">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                id="file-input"
                className="hidden-input"
              />
              <label htmlFor="file-input" className="file-upload-btn">
                <PaperClipIcon className="btn-icon" />
                Add Attachment
              </label>
            </div>

            {files.length > 0 && (
              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <button
                      type="button"
                      className="remove-file-btn"
                      onClick={() => removeFile(index)}
                    >
                      <XMarkIcon className="btn-icon" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="form-actions">
            <Link to="/communications" className="cancel-btn">
              Cancel
            </Link>
            <button type="submit" className="submit-btn">
              Send Reply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReplyCommunication; 