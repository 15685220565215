import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function EmailNotifications() {
  const navigate = useNavigate();
  const [emailSettings, setEmailSettings] = useState({
    enabled: true,
    frequency: 'immediate',
    digest: 'daily',
    notifications: {
      incidents: true,
      patientUpdates: true,
      systemAlerts: true,
      reports: true,
      statusChanges: true,
      assignments: true
    },
    quietHours: {
      enabled: false,
      start: '22:00',
      end: '07:00'
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email settings saved:', emailSettings);
    // TODO: Save to backend
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Email Notifications</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="preferences-section">
            <h3>Email Preferences</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.enabled}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    enabled: e.target.checked
                  })}
                />
                Enable Email Notifications
              </label>
            </div>

            <div className="form-group">
              <label>Notification Frequency</label>
              <select
                value={emailSettings.frequency}
                onChange={(e) => setEmailSettings({
                  ...emailSettings,
                  frequency: e.target.value
                })}
                className="compact-input"
              >
                <option value="immediate">Immediate</option>
                <option value="hourly">Hourly Digest</option>
                <option value="daily">Daily Digest</option>
              </select>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Notification Types</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.incidents}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      incidents: e.target.checked
                    }
                  })}
                />
                Incident Updates
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.patientUpdates}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      patientUpdates: e.target.checked
                    }
                  })}
                />
                Patient Updates
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.systemAlerts}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      systemAlerts: e.target.checked
                    }
                  })}
                />
                System Alerts
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.reports}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      reports: e.target.checked
                    }
                  })}
                />
                Reports & Analytics
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.statusChanges}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      statusChanges: e.target.checked
                    }
                  })}
                />
                Status Changes
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.notifications.assignments}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    notifications: {
                      ...emailSettings.notifications,
                      assignments: e.target.checked
                    }
                  })}
                />
                Task Assignments
              </label>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Quiet Hours</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailSettings.quietHours.enabled}
                  onChange={(e) => setEmailSettings({
                    ...emailSettings,
                    quietHours: {
                      ...emailSettings.quietHours,
                      enabled: e.target.checked
                    }
                  })}
                />
                Enable Quiet Hours
              </label>
            </div>

            {emailSettings.quietHours.enabled && (
              <div className="form-row">
                <div className="form-group half">
                  <label>Start Time</label>
                  <input
                    type="time"
                    value={emailSettings.quietHours.start}
                    onChange={(e) => setEmailSettings({
                      ...emailSettings,
                      quietHours: {
                        ...emailSettings.quietHours,
                        start: e.target.value
                      }
                    })}
                    className="compact-input"
                  />
                </div>
                <div className="form-group half">
                  <label>End Time</label>
                  <input
                    type="time"
                    value={emailSettings.quietHours.end}
                    onChange={(e) => setEmailSettings({
                      ...emailSettings,
                      quietHours: {
                        ...emailSettings.quietHours,
                        end: e.target.value
                      }
                    })}
                    className="compact-input"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Settings
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmailNotifications; 