import React, { useState } from 'react';
import { ChevronLeftIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function AuditLog() {
  const [logs] = useState([
    {
      id: 1,
      action: 'User Login',
      user: 'John Smith',
      details: 'Successfully logged in',
      timestamp: '2024-03-19 14:23:45',
      type: 'auth',
      ip: '192.168.1.100'
    },
    {
      id: 2,
      action: 'Create Incident',
      user: 'Sarah Johnson',
      details: 'Created incident #2024-001',
      timestamp: '2024-03-19 14:20:30',
      type: 'incident',
      ip: '192.168.1.101'
    },
    {
      id: 3,
      action: 'Update Patient',
      user: 'Mike Wilson',
      details: 'Updated patient status in incident #2024-001',
      timestamp: '2024-03-19 14:15:22',
      type: 'patient',
      ip: '192.168.1.102'
    },
    {
      id: 4,
      action: 'System Setting Changed',
      user: 'Admin User',
      details: 'Modified alert notification settings',
      timestamp: '2024-03-19 14:10:15',
      type: 'system',
      ip: '192.168.1.103'
    }
  ]);

  const typeColors = {
    auth: '#228be6',
    incident: '#fa5252',
    patient: '#40c057',
    system: '#be4bdb'
  };

  return (
    <div className="audit-log-page">
      <div className="page-header">
        <div className="header-content">
          <h1>Audit Log</h1>
          <p className="header-description">View system activity and user actions</p>
        </div>
        <div className="header-actions">
          <Link to="/admin" className="back-btn">
            <ChevronLeftIcon className="btn-icon" />
            Back to Admin
          </Link>
          <button className="filter-btn">
            <FunnelIcon className="btn-icon" />
            Filter
          </button>
        </div>
      </div>

      <div className="audit-table-container">
        <table className="audit-table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Action</th>
              <th>User</th>
              <th>Details</th>
              <th>Type</th>
              <th>IP Address</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td className="timestamp">{log.timestamp}</td>
                <td className="action">{log.action}</td>
                <td className="user">{log.user}</td>
                <td className="details">{log.details}</td>
                <td className="type">
                  <span className="type-badge" style={{ backgroundColor: `${typeColors[log.type]}15`, color: typeColors[log.type] }}>
                    {log.type}
                  </span>
                </td>
                <td className="ip">{log.ip}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AuditLog; 