import React, { useState } from 'react';
import { 
  TruckIcon,
  UserGroupIcon,
  MapPinIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import AddResourceModal from '../components/modals/AddResourceModal';

export function Resources() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newResource, setNewResource] = useState({
    unit: '',
    type: 'Engine',
    status: 'Available',
    crew: 0,
    location: '',
    currentTask: ''
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [resourceData, setResourceData] = useState([
    {
      unit: "E-31",
      type: "Engine",
      status: "Available",
      crew: 4,
      location: "Station 1",
      lastUpdate: "10 mins ago",
      currentTask: "At station"
    },
    {
      unit: "A-42",
      type: "Ambulance",
      status: "Assigned",
      crew: 2,
      location: "City General Hospital",
      lastUpdate: "5 mins ago",
      currentTask: "Transporting to hospital"
    },
    {
      unit: "R-51",
      type: "Rescue",
      status: "Out of Service",
      crew: 0,
      location: "Maintenance Facility",
      lastUpdate: "2 hours ago",
      currentTask: "Scheduled maintenance"
    },
    {
      unit: "E-32",
      type: "Engine",
      status: "Available",
      crew: 3,
      location: "Station 2",
      lastUpdate: "15 mins ago",
      currentTask: "At station"
    },
    {
      unit: "A-41",
      type: "Ambulance",
      status: "Assigned",
      crew: 2,
      location: "Scene",
      lastUpdate: "2 mins ago",
      currentTask: "On scene with patient"
    }
  ]);

  // Update status types to remove At Station
  const [statusTypes] = useState([
    'Available',
    'Responding',
    'On Scene',
    'Assigned',
    'Out of Service'
  ]);

  // Get both system status types and any custom ones from current resources
  const allStatuses = [...new Set([
    ...statusTypes,
    ...resourceData.map(r => r.status)
  ])].sort();
  
  // Create dynamic stats object for all possible statuses
  const resourceStats = allStatuses.reduce((acc, status) => ({
    ...acc,
    [status]: resourceData.filter(r => r.status === status).length
  }), {});

  const getStatusColor = (status) => {
    switch (status) {
      case 'Available':
        return '#40c057';  // Green
      case 'Responding':
      case 'On Scene':
      case 'Assigned':
        return '#fab005';  // Yellow
      case 'Out of Service':
        return '#fa5252';  // Red
      default:
        return '#868e96';  // Gray
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewResource(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Add resource to the list
    console.log('New resource:', newResource);
    setIsModalOpen(false);
    setNewResource({
      unit: '',
      type: 'Engine',
      status: 'Available',
      crew: 0,
      location: '',
      currentTask: ''
    });
  };

  const handleAddResource = (newResource) => {
    const resourceToAdd = {
      unit: newResource.unitId,
      type: newResource.type,
      status: newResource.status,
      crew: 0,
      location: 'Not Set',
      currentTask: 'Not Set',
      lastUpdate: 'Just now'
    };
    
    setResourceData(prevData => [resourceToAdd, ...prevData]);
    setIsAddModalOpen(false);
  };

  const handleEdit = (resource) => {
    setSelectedResource(resource);
    setIsEditModalOpen(true);
  };

  const handleUpdateResource = (updatedResource) => {
    setResourceData(prevData => 
      prevData.map(item => 
        item.unit === selectedResource.unit ?
        {
          ...item,
          unit: updatedResource.unitId,
          type: updatedResource.type,
          status: updatedResource.status,
        } : item
      )
    );
    setIsEditModalOpen(false);
  };

  const [userRole, setUserRole] = useState('ADMIN');

  const handleDelete = (unitId) => {
    if (window.confirm('Are you sure you want to delete this resource?')) {
      setResourceData(prevData => prevData.filter(item => item.unit !== unitId));
    }
  };

  return (
    <div className="resources">
      <div className="page-header">
        <h1>Resources</h1>
        <button 
          className="add-resource-btn"
          onClick={() => setIsAddModalOpen(true)}
        >
          <PlusIcon className="btn-icon" />
          Add Resource
        </button>
      </div>

      <div className="stats-grid">
        {allStatuses.map(status => (
          <div className="stat-card" key={status}>
            <div className="stat-card-content">
              <div className="stat-header">
                <TruckIcon 
                  className="card-icon" 
                  style={{ color: getStatusColor(status) }} 
                />
                <h2>{status}</h2>
              </div>
              <div className="stat-body">
                <div className="stat-number">{resourceStats[status] || 0}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="table-container">
        <table className="resources-table">
          <thead>
            <tr>
              <th>Unit</th>
              <th>Type</th>
              <th>Status</th>
              <th>Crew</th>
              <th>Location</th>
              <th>Current Task</th>
              <th>Last Update</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {resourceData.map((resource) => (
              <tr key={resource.unit}>
                <td className="unit-cell">
                  <TruckIcon className="table-icon" />
                  {resource.unit}
                </td>
                <td>{resource.type}</td>
                <td>
                  <span 
                    className="status-badge"
                    style={{ 
                      backgroundColor: `${getStatusColor(resource.status)}20`,
                      color: getStatusColor(resource.status)
                    }}
                  >
                    {resource.status}
                  </span>
                </td>
                <td>
                  <div className="crew-cell">
                    <UserGroupIcon className="table-icon" />
                    {resource.crew}
                  </div>
                </td>
                <td>
                  <div className="location-cell">
                    <MapPinIcon className="table-icon" />
                    {resource.location}
                  </div>
                </td>
                <td>{resource.currentTask}</td>
                <td>{resource.lastUpdate}</td>
                <td>
                  <div className="action-buttons">
                    <button 
                      className="edit-button"
                      onClick={() => handleEdit(resource)}
                    >
                      Edit
                    </button>
                    {userRole === 'ADMIN' && (
                      <button 
                        className="delete-button"
                        onClick={() => handleDelete(resource.unit)}
                        style={{
                          padding: '6px 12px',
                          backgroundColor: '#dc3545',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px'
                        }}
                      >
                        <TrashIcon style={{ width: '20px', height: '20px' }} />
                        Delete
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddResourceModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddResource}
      />

      <AddResourceModal 
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedResource(null);
        }}
        onSubmit={handleUpdateResource}
        initialData={{
          unitId: selectedResource?.unit || '',
          type: selectedResource?.type || 'Engine',
          status: selectedResource?.status || 'Available'
        }}
        isEditing={true}
        editingResource={selectedResource}
      />
    </div>
  );
}

export default Resources; 