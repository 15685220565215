import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  UsersIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  FunnelIcon
} from '@heroicons/react/24/outline';
import CreateUserModal from '../../components/modals/CreateUserModal';
import ViewUserModal from '../../components/modals/ViewUserModal';

function UserManagement() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      name: 'John Smith',
      email: 'john.smith@example.com',
      role: 'Administrator',
      department: 'IT',
      status: 'Active',
      lastActive: '2024-03-15 14:30',
      avatar: null,
      permissions: {
        alerts: true,
        announcements: true,
        reports: true,
        userManagement: true
      }
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      email: 'sarah.j@example.com',
      role: 'Emergency Manager',
      department: 'Emergency Response',
      status: 'Active',
      lastActive: '2024-03-15 15:45',
      avatar: null,
      permissions: {
        alerts: true,
        announcements: true,
        reports: true,
        userManagement: false
      }
    },
    {
      id: 3,
      name: 'Mike Wilson',
      email: 'mike.w@example.com',
      role: 'Staff',
      department: 'Operations',
      status: 'Inactive',
      lastActive: '2024-03-14 09:15',
      avatar: null,
      permissions: {
        alerts: false,
        announcements: false,
        reports: true,
        userManagement: false
      }
    }
  ]);

  const getRoleColor = (role) => {
    switch (role) {
      case 'Administrator':
        return '#fa5252';
      case 'Emergency Manager':
        return '#40c057';
      case 'Staff':
        return '#228be6';
      default:
        return '#868e96';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return '#40c057';
      case 'Inactive':
        return '#868e96';
      case 'Suspended':
        return '#fa5252';
      default:
        return '#fab005';
    }
  };

  const handleCreateOrUpdate = (userData) => {
    if (selectedUser) {
      // Update existing user
      setUsers(users.map(user => {
        if (user.id === selectedUser.id) {
          return {
            ...user,
            ...userData,
            lastActive: user.lastActive
          };
        }
        return user;
      }));
    } else {
      // Create new user
      const newUser = {
        id: users.length + 1,
        ...userData,
        lastActive: 'Never',
        avatar: null
      };
      setUsers([newUser, ...users]);
    }
    setIsCreateModalOpen(false);
    setSelectedUser(null);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsCreateModalOpen(true);
  };

  const handleView = (user) => {
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleStatusChange = (userId, newStatus) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        return {
          ...user,
          status: newStatus
        };
      }
      return user;
    }));
  };

  const filteredUsers = users.filter(user => {
    const matchesSearch = user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        user.department.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesRole = filterRole === 'all' || user.role === filterRole;
    const matchesStatus = filterStatus === 'all' || user.status === filterStatus;
    
    return matchesSearch && matchesRole && matchesStatus;
  });

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/admin')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Admin
        </button>
        <div className="header-content">
          <div className="header-title">
            <UsersIcon className="header-icon" style={{ color: '#228be6' }} />
            <div>
              <h1>User Management</h1>
              <p className="header-description">Manage user accounts and permissions</p>
            </div>
          </div>
        </div>
      </div>

      <div className="users-container">
        <div className="controls-row">
          <div className="search-filters">
            <div className="search-box">
              <MagnifyingGlassIcon className="search-icon" />
              <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="filters">
              <div className="filter-group">
                <FunnelIcon className="filter-icon" />
                <select
                  value={filterRole}
                  onChange={(e) => setFilterRole(e.target.value)}
                >
                  <option value="all">All Roles</option>
                  <option value="Administrator">Administrator</option>
                  <option value="Emergency Manager">Emergency Manager</option>
                  <option value="Staff">Staff</option>
                </select>
              </div>

              <div className="filter-group">
                <select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="all">All Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Suspended">Suspended</option>
                </select>
              </div>
            </div>
          </div>

          <button 
            className="primary-button"
            onClick={() => {
              setSelectedUser(null);
              setIsCreateModalOpen(true);
            }}
          >
            <PlusIcon width={20} height={20} />
            Add New User
          </button>
        </div>

        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>User</th>
                <th>Role</th>
                <th>Department</th>
                <th>Status</th>
                <th>Last Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.id}>
                  <td>
                    <div className="user-info">
                      <div className="user-avatar" style={{ backgroundColor: `${getRoleColor(user.role)}20` }}>
                        {user.avatar ? (
                          <img src={user.avatar} alt={user.name} />
                        ) : (
                          <span style={{ color: getRoleColor(user.role) }}>{user.name.charAt(0)}</span>
                        )}
                      </div>
                      <div className="user-details">
                        <span className="user-name">{user.name}</span>
                        <span className="user-email">{user.email}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span 
                      className="badge role-badge"
                      style={{ 
                        backgroundColor: `${getRoleColor(user.role)}15`,
                        color: getRoleColor(user.role)
                      }}
                    >
                      {user.role}
                    </span>
                  </td>
                  <td>{user.department}</td>
                  <td>
                    <span 
                      className="badge status-badge"
                      style={{ 
                        backgroundColor: `${getStatusColor(user.status)}15`,
                        color: getStatusColor(user.status)
                      }}
                    >
                      <span 
                        className="status-dot"
                        style={{ backgroundColor: getStatusColor(user.status) }}
                      />
                      {user.status}
                    </span>
                  </td>
                  <td className="timestamp-cell">{user.lastActive}</td>
                  <td>
                    <div className="action-buttons">
                      <button 
                        className="icon-button"
                        onClick={() => handleEdit(user)}
                        style={{ color: '#228be6' }}
                      >
                        Edit
                      </button>
                      <button 
                        className="icon-button"
                        onClick={() => handleView(user)}
                        style={{ color: '#40c057' }}
                      >
                        View
                      </button>
                      {user.status === 'Active' ? (
                        <button 
                          className="icon-button"
                          onClick={() => handleStatusChange(user.id, 'Suspended')}
                          style={{ color: '#fa5252' }}
                        >
                          Suspend
                        </button>
                      ) : (
                        <button 
                          className="icon-button"
                          onClick={() => handleStatusChange(user.id, 'Active')}
                          style={{ color: '#40c057' }}
                        >
                          Activate
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <CreateUserModal 
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
          setSelectedUser(null);
        }}
        user={selectedUser}
        onSubmit={handleCreateOrUpdate}
      />

      <ViewUserModal 
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
          setSelectedUser(null);
        }}
        user={selectedUser}
      />
    </div>
  );
}

export default UserManagement; 