import React, { useState } from 'react';
import { 
  PlusIcon, 
  MagnifyingGlassIcon,
  FunnelIcon,
  ArrowPathIcon,
  DocumentTextIcon,
  DocumentChartBarIcon,
  ClipboardDocumentListIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function Reports() {
  const [reports] = useState([
    {
      id: 1,
      title: 'Daily Incident Summary',
      type: 'Incident Report',
      author: 'John Smith',
      created: '2024-02-20 17:00',
      status: 'Published',
      incident: 'Multiple',
      period: 'Feb 20, 2024'
    },
    {
      id: 2,
      title: 'Resource Allocation Report',
      type: 'Resource Report',
      author: 'Sarah Johnson',
      created: '2024-02-20 16:30',
      status: 'Draft',
      incident: 'Structure Fire - 123 Main St',
      period: 'Feb 20, 2024'
    },
    {
      id: 3,
      title: 'Personnel Activity Log',
      type: 'Activity Report',
      author: 'Mike Davis',
      created: '2024-02-20 16:00',
      status: 'Under Review',
      incident: 'All Units',
      period: 'Feb 20, 2024'
    }
  ]);

  const [searchTerm, setSearchTerm] = useState('');

  const getTypeIcon = (type) => {
    switch (type) {
      case 'Incident Report':
        return <DocumentTextIcon className="type-icon incident" />;
      case 'Resource Report':
        return <DocumentChartBarIcon className="type-icon resource" />;
      case 'Activity Report':
        return <ClipboardDocumentListIcon className="type-icon activity" />;
      default:
        return null;
    }
  };

  return (
    <div className="reports-page">
      <div className="page-header">
        <h1>Reports</h1>
        <div className="header-actions">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="Search reports..." 
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <MagnifyingGlassIcon className="search-icon" />
          </div>
          <button className="filter-btn">
            <FunnelIcon className="btn-icon" />
            Filter
          </button>
          <button className="refresh-btn">
            <ArrowPathIcon className="btn-icon" />
            Refresh
          </button>
          <Link to="/reports/new" className="create-btn">
            <PlusIcon className="btn-icon" />
            New Report
          </Link>
        </div>
      </div>

      <div className="reports-table-container">
        <table className="reports-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Author</th>
              <th>Created</th>
              <th>Status</th>
              <th>Incident</th>
              <th>Period</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports
              .filter(report => 
                searchTerm === '' ||
                report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
                report.incident.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(report => (
                <tr key={report.id} className="report-row">
                  <td>{report.title}</td>
                  <td>
                    <div className="type-container">
                      {getTypeIcon(report.type)}
                      <span>{report.type}</span>
                    </div>
                  </td>
                  <td>{report.author}</td>
                  <td>{report.created}</td>
                  <td>
                    <span className={`status-badge ${report.status.toLowerCase().replace(' ', '-')}`}>
                      {report.status}
                    </span>
                  </td>
                  <td>{report.incident}</td>
                  <td>{report.period}</td>
                  <td>
                    <div className="action-buttons">
                      <Link to={`/reports/view/${report.id}`} className="view-btn">
                        View
                      </Link>
                      <Link to={`/reports/edit/${report.id}`} className="edit-btn">
                        Edit
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Reports; 