import React, { useState } from 'react';
import { 
  PlusIcon, 
  MagnifyingGlassIcon,
  FunnelIcon,
  ArrowPathIcon 
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function Personnel() {
  const [personnel] = useState([
    {
      id: 1,
      name: 'John Smith',
      status: 'On Duty',
      assignment: 'Command Unit 1',
      badge: '12345',
      phone: '(555) 123-4567',
      email: 'john.smith@emergency.com'
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      status: 'On Call',
      assignment: 'Medical Unit 3',
      badge: '12346',
      phone: '(555) 123-4568',
      email: 'sarah.johnson@emergency.com'
    },
    {
      id: 3,
      name: 'Mike Davis',
      status: 'Off Duty',
      assignment: 'Engine 5',
      badge: '12347',
      phone: '(555) 123-4569',
      email: 'mike.davis@emergency.com'
    }
  ]);

  return (
    <div className="personnel-page">
      <div className="page-header">
        <h1>Personnel</h1>
        <div className="header-actions">
          <div className="search-bar">
            <MagnifyingGlassIcon className="search-icon" />
            <input 
              type="text" 
              placeholder="Search personnel..." 
              className="search-input"
            />
          </div>
          <button className="filter-btn">
            <FunnelIcon className="btn-icon" />
            Filter
          </button>
          <button className="refresh-btn">
            <ArrowPathIcon className="btn-icon" />
            Refresh
          </button>
          <button className="create-btn">
            <PlusIcon className="btn-icon" />
            Add Personnel
          </button>
        </div>
      </div>

      <div className="personnel-table-container">
        <table className="personnel-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Current Assignment</th>
              <th>ID Number</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {personnel.map(person => (
              <tr key={person.id} className="personnel-row">
                <td>{person.name}</td>
                <td>
                  <span className={`status-badge ${person.status.toLowerCase().replace(' ', '-')}`}>
                    {person.status}
                  </span>
                </td>
                <td>{person.assignment}</td>
                <td>{person.badge}</td>
                <td>{person.phone}</td>
                <td>{person.email}</td>
                <td>
                  <div className="action-buttons">
                    <Link to={`/personnel/edit/${person.id}`} className="edit-btn">
                      Edit
                    </Link>
                    <button className="view-btn">View</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Personnel; 