import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeftIcon, 
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import './Announcements.css';

function Announcements() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    expirationTime: '',
    notificationChannels: {
      email: false,
      mobile: false
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      notificationChannels: {
        ...prev.notificationChannels,
        [name]: checked
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your submit logic here
    console.log('Announcement Data:', formData);
    // Navigate back to communications after successful submission
    navigate('/communications');
  };

  return (
    <div className="announcements-container">
      <div className="announcements-header">
        <button className="announcements-back-button" onClick={() => navigate('/communications')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Communications
        </button>
        <div className="announcements-header-content">
          <div className="announcements-title">
            <MegaphoneIcon className="announcements-icon" />
            <div>
              <h1>Create Announcement</h1>
              <p>Send a new announcement to all users</p>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="announcements-form">
        <div className="form-section">
          <h2>Announcement Details</h2>
          
          <div className="form-group">
            <label htmlFor="title">Announcement Title*</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="Enter announcement title"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message*</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
              placeholder="Enter your announcement message"
              rows="4"
            />
          </div>

          <div className="form-group">
            <label htmlFor="expirationTime">Expiration Time*</label>
            <input
              type="datetime-local"
              id="expirationTime"
              name="expirationTime"
              value={formData.expirationTime}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="form-section">
          <h2>Distribution Channels</h2>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="email"
                checked={formData.notificationChannels.email}
                onChange={handleCheckboxChange}
              />
              Email Distribution
            </label>
            <label>
              <input
                type="checkbox"
                name="mobile"
                checked={formData.notificationChannels.mobile}
                onChange={handleCheckboxChange}
              />
              Mobile Push Notification
            </label>
          </div>
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            className="cancel-button"
            onClick={() => navigate('/communications')}
          >
            Cancel
          </button>
          <button type="submit" className="submit-button">
            Send Announcement
          </button>
        </div>
      </form>
    </div>
  );
}

export default Announcements; 