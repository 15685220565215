import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { createEventLogEntry } from '../utils/eventLogUtils';
import { useEventLog } from '../contexts/EventLogContext';

function PatientDetail() {
  const { id, prid } = useParams();
  const [patient, setPatient] = useState({
    prid: prid,
    triageTagNumber: "T-1234",
    triageLevel: "Red",
    location: "Zone 1",
    chiefComplaint: "Chest Pain",
    disposition: "Transported",
    hospital: "Memorial Hospital",
    unitAssigned: "Medic 12",
    lastUpdated: "2024-03-20T10:45:00",
    initials: "JS",
    notes: [],
    vitals: [],
    relatedTags: [],
  });
  const [newNote, setNewNote] = useState('');
  const [newTag, setNewTag] = useState('');
  const { addEventLogEntry } = useEventLog();

  const handleFieldUpdate = (field, value, oldValue) => {
    setPatient(prev => ({
      ...prev,
      [field]: value,
      lastUpdated: new Date().toISOString()
    }));

    // Create event log entry
    const logEntry = createEventLogEntry(
      `Patient ${prid} ${field} updated: ${oldValue} → ${value}`,
      id,
      patient.initials,
      field === 'triageLevel' && (value === 'Red' || value === 'Black')
    );

    // Add to event log through context
    addEventLogEntry(logEntry);
  };

  const addNote = (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    const note = {
      id: Date.now(),
      text: newNote,
      timestamp: new Date().toISOString(),
      initials: patient.initials
    };

    setPatient(prev => ({
      ...prev,
      notes: [note, ...prev.notes],
      lastUpdated: new Date().toISOString()
    }));

    // Create event log entry for the note
    const logEntry = createEventLogEntry(
      `Note added to Patient ${prid}: ${newNote}`,
      id,
      patient.initials,
      false
    );

    // Add to event log through context
    addEventLogEntry(logEntry);
    
    setNewNote('');
  };

  const addRelatedTag = (e) => {
    e.preventDefault();
    if (!newTag.trim()) return;

    setPatient(prev => ({
      ...prev,
      relatedTags: [...prev.relatedTags, newTag.trim()],
      lastUpdated: new Date().toISOString()
    }));

    // Create event log entry for related tag
    const logEntry = createEventLogEntry(
      `Related tag added to Patient ${prid}: ${newTag}`,
      id,
      patient.initials,
      false
    );

    // Add to event log through context
    addEventLogEntry(logEntry);

    setNewTag('');
  };

  const removeRelatedTag = (tagToRemove) => {
    setPatient(prev => ({
      ...prev,
      relatedTags: prev.relatedTags.filter(tag => tag !== tagToRemove),
      lastUpdated: new Date().toISOString()
    }));
  };

  return (
    <div className="patient-detail-page">
      <div className="page-header">
        <h1>Patient Details</h1>
        <div className="header-actions">
          <span className="incident-id">Incident #{id}</span>
          <span className="patient-id">Patient #{prid}</span>
          <Link to={`/incident/${id}/patients`} className="nav-link">
            Back to Patient List
          </Link>
        </div>
      </div>

      <div className="patient-detail-container">
        <div className="patient-info-section">
          <h2>Patient Information</h2>
          <div className="detail-grid">
            <div className="detail-item">
              <label>Triage Tag Number</label>
              <input
                type="text"
                value={patient.triageTagNumber}
                onChange={(e) => handleFieldUpdate('triageTagNumber', e.target.value, patient.triageTagNumber)}
                placeholder="Enter triage tag number"
              />
            </div>

            <div className="detail-item">
              <label>Triage Level</label>
              <select
                value={patient.triageLevel}
                onChange={(e) => handleFieldUpdate('triageLevel', e.target.value, patient.triageLevel)}
              >
                <option value="Red">Red (Immediate)</option>
                <option value="Yellow">Yellow (Delayed)</option>
                <option value="Green">Green (Minor)</option>
                <option value="Black">Black (Deceased)</option>
              </select>
            </div>

            <div className="detail-item">
              <label>Location</label>
              <input
                type="text"
                value={patient.location}
                onChange={(e) => handleFieldUpdate('location', e.target.value, patient.location)}
              />
            </div>

            <div className="detail-item">
              <label>Chief Complaint</label>
              <input
                type="text"
                value={patient.chiefComplaint}
                onChange={(e) => handleFieldUpdate('chiefComplaint', e.target.value, patient.chiefComplaint)}
              />
            </div>

            <div className="detail-item">
              <label>Unit Assigned</label>
              <input
                type="text"
                value={patient.unitAssigned}
                onChange={(e) => handleFieldUpdate('unitAssigned', e.target.value, patient.unitAssigned)}
              />
            </div>

            <div className="detail-item">
              <label>Disposition</label>
              <select
                value={patient.disposition}
                onChange={(e) => handleFieldUpdate('disposition', e.target.value, patient.disposition)}
              >
                <option value="On Scene">On Scene</option>
                <option value="Transported">Transported</option>
                <option value="Refused">Refused Transport</option>
                <option value="DOA">DOA</option>
              </select>
            </div>

            {patient.disposition === 'Transported' && (
              <div className="detail-item">
                <label>Hospital</label>
                <select
                  value={patient.hospital || ''}
                  onChange={(e) => handleFieldUpdate('hospital', e.target.value, patient.hospital)}
                >
                  <option value="">Select Hospital</option>
                  <option value="Memorial Hospital">Memorial Hospital</option>
                  <option value="General Hospital">General Hospital</option>
                  <option value="University Medical">University Medical</option>
                  <option value="Children's Hospital">Children's Hospital</option>
                  <option value="Trauma Center">Trauma Center</option>
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="patient-notes-section">
          <h2>Patient Notes</h2>
          
          <form onSubmit={addNote} className="note-form">
            <div className="note-input-container">
              <input
                type="text"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Add a note..."
                className="note-input"
              />
              <button type="submit" className="add-note-btn">
                Add Note
              </button>
            </div>
          </form>

          <div className="notes-list">
            {patient.notes.map(note => (
              <div key={note.id} className="note-item">
                <div className="note-header">
                  <span className="note-time">
                    {new Date(note.timestamp).toLocaleTimeString()}
                  </span>
                  <span className="note-initials">{note.initials}</span>
                </div>
                <div className="note-text">{note.text}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="related-tags-section">
          <h2>Related Triage Tag Numbers</h2>
          <form onSubmit={addRelatedTag} className="tag-form">
            <div className="tag-input-container">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                placeholder="Enter related triage tag number..."
                className="tag-input"
              />
              <button type="submit" className="add-tag-btn">
                Add Tag
              </button>
            </div>
          </form>

          <div className="tags-list">
            {patient.relatedTags.map(tag => (
              <div key={tag} className="tag-item">
                <span className="tag-text">{tag}</span>
                <button
                  className="remove-tag-btn"
                  onClick={() => removeRelatedTag(tag)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="form-actions">
          <button className="submit-btn">Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default PatientDetail; 