import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  FunnelIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useLists } from '../contexts/ListsContext';

export function PatientTracking() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getListByName } = useLists();
  const hospitals = getListByName('Hospitals');
  const triageStatuses = getListByName('Triage Status');
  
  // Mock incident data
  const incidentData = {
    masterNumber: id,
    patients: [
      {
        patientId: `${id}-01`,
        triageLevel: 'red',
        complaint: 'Chest Pain',
        disposition: 'Transport',
        hospitalDestination: 'County General',
        timeAdded: '14:30'
      },
      {
        patientId: `${id}-02`,
        triageLevel: 'yellow',
        complaint: 'Fractured Ankle',
        disposition: 'Transport',
        hospitalDestination: 'Mercy Hospital',
        timeAdded: '14:35'
      },
      {
        patientId: `${id}-03`,
        triageLevel: 'green',
        complaint: 'Minor Lacerations',
        disposition: 'Treatment Complete',
        hospitalDestination: null,
        timeAdded: '14:40'
      },
      {
        patientId: `${id}-04`,
        triageLevel: 'black',
        complaint: 'Cardiac Arrest',
        disposition: 'DOA',
        hospitalDestination: null,
        timeAdded: '14:45'
      }
    ]
  };

  const [patients, setPatients] = useState(incidentData.patients);
  const [patientCount, setPatientCount] = useState(incidentData.patients.length + 1);
  const [newPatient, setNewPatient] = useState({
    triageLevel: '',
    complaint: '',
    disposition: '',
    hospitalDestination: ''
  });
  const [showAddPatient, setShowAddPatient] = useState(false);

  // Add filter state
  const [filters, setFilters] = useState({
    triageLevel: '',
    disposition: ''
  });

  // Add modal state
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPatient, setEditingPatient] = useState(null);

  // Add filter function
  const filteredPatients = patients.filter(patient => {
    const matchesTriage = !filters.triageLevel || patient.triageLevel === filters.triageLevel;
    const matchesDisposition = !filters.disposition || patient.disposition === filters.disposition;
    return matchesTriage && matchesDisposition;
  });

  // Add state for tracking changes
  const [changes, setChanges] = useState([]);

  // Add function to track changes
  const trackChange = (field, oldValue, newValue) => {
    const change = {
      field,
      oldValue,
      newValue,
      timestamp: new Date().toLocaleTimeString('en-US', { 
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      }),
      user: 'Current User' // Replace with actual user info from your auth system
    };
    setChanges(prevChanges => [change, ...prevChanges]);
  };

  const handleAddPatient = (e) => {
    e.preventDefault();
    
    const patientData = {
      patientId: `${incidentData.masterNumber}-${patientCount.toString().padStart(2, '0')}`,
      triageLevel: newPatient.triageLevel,
      complaint: newPatient.complaint,
      disposition: newPatient.disposition,
      hospitalDestination: newPatient.disposition === 'Transport' ? newPatient.hospitalDestination : null,
      timeAdded: new Date().toLocaleTimeString('en-US', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit'
      })
    };

    // Add to patients list
    setPatients(prevPatients => [patientData, ...prevPatients]);
    setPatientCount(prevCount => prevCount + 1);

    // Reset form
    setNewPatient({
      triageLevel: '',
      complaint: '',
      disposition: '',
      hospitalDestination: ''
    });

    setShowAddPatient(false);
  };

  // Add triage level color mapping
  const getTriageColor = (level) => {
    const colors = {
      red: '#fa5252',
      yellow: '#fab005',
      green: '#40c057',
      black: '#495057'
    };
    return colors[level] || '#ced4da';
  };

  // Add edit handler
  const handleEdit = (patient) => {
    setEditingPatient(patient);
    setShowEditModal(true);
  };

  // Add delete handler
  const handleDelete = (patientId) => {
    if (window.confirm('Are you sure you want to remove this patient?')) {
      setPatients(prevPatients => 
        prevPatients.filter(p => p.patientId !== patientId)
      );
    }
  };

  // Add save edit handler
  const handleSaveEdit = (e) => {
    e.preventDefault();
    setPatients(prevPatients =>
      prevPatients.map(p =>
        p.patientId === editingPatient.patientId ? editingPatient : p
      )
    );
    setShowEditModal(false);
    setEditingPatient(null);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Incident
        </button>
        <h1>Patient Tracking - Incident {id}</h1>
      </div>

      {/* Add Patient Form */}
      <div className="detail-card">
        <div className="card-header">
          <div className="header-left" onClick={() => setShowAddPatient(!showAddPatient)}>
            <h2>Add Patient</h2>
            {showAddPatient ? 
              <ChevronUpIcon width={14} height={14} className="header-icon" /> : 
              <ChevronDownIcon width={14} height={14} className="header-icon" />
            }
          </div>
        </div>
        
        {showAddPatient && (
          <form onSubmit={handleAddPatient} className="add-patient-form">
            <div className="form-group">
              <label>Patient ID</label>
              <div className="patient-id-display">
                {`${incidentData.masterNumber}-${patientCount.toString().padStart(2, '0')}`}
              </div>
            </div>

            <div className="form-group">
              <label>Triage Level</label>
              <select
                value={newPatient.triageLevel}
                onChange={(e) => setNewPatient({
                  ...newPatient,
                  triageLevel: e.target.value
                })}
                required
              >
                <option value="">Select triage level...</option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
                <option value="green">Green</option>
                <option value="black">Black</option>
              </select>
            </div>

            <div className="form-group">
              <label>Chief Complaint</label>
              <input
                type="text"
                value={newPatient.complaint}
                onChange={(e) => setNewPatient({
                  ...newPatient,
                  complaint: e.target.value
                })}
                placeholder="Enter chief complaint"
                required
              />
            </div>

            <div className="form-group">
              <label>Disposition</label>
              <select
                value={newPatient.disposition}
                onChange={(e) => setNewPatient({
                  ...newPatient,
                  disposition: e.target.value
                })}
                required
              >
                <option value="">Select disposition...</option>
                <option value="Transport">Transport</option>
                <option value="Refusal">Refusal</option>
                <option value="DOA">DOA</option>
                <option value="Treatment Complete">Treatment Complete</option>
              </select>
            </div>

            {newPatient.disposition === 'Transport' && (
              <div className="form-group">
                <label>Hospital Destination</label>
                <select
                  value={newPatient.hospitalDestination}
                  onChange={(e) => setNewPatient({
                    ...newPatient,
                    hospitalDestination: e.target.value
                  })}
                  required
                >
                  <option value="">Select hospital...</option>
                  <option value="County General">County General</option>
                  <option value="Mercy Hospital">Mercy Hospital</option>
                  <option value="St. Mary's">St. Mary's</option>
                  <option value="VA Medical Center">VA Medical Center</option>
                </select>
              </div>
            )}

            <div className="form-actions">
              <button type="submit" className="submit-button">
                Add Patient
              </button>
            </div>
          </form>
        )}
      </div>

      {/* Patient List with Filters */}
      <div className="detail-card">
        <div className="card-header">
          <h2>Current Patients</h2>
          <div className="filter-controls">
            <div className="filter-group">
              <FunnelIcon width={14} height={14} />
              <select
                value={filters.triageLevel}
                onChange={(e) => setFilters(prev => ({
                  ...prev,
                  triageLevel: e.target.value
                }))}
                className="filter-select"
              >
                <option value="">All Triage Levels</option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
                <option value="green">Green</option>
                <option value="black">Black</option>
              </select>
              <select
                value={filters.disposition}
                onChange={(e) => setFilters(prev => ({
                  ...prev,
                  disposition: e.target.value
                }))}
                className="filter-select"
              >
                <option value="">All Dispositions</option>
                <option value="Transport">Transport</option>
                <option value="Refusal">Refusal</option>
                <option value="DOA">DOA</option>
                <option value="Treatment Complete">Treatment Complete</option>
              </select>
            </div>
          </div>
        </div>
        <div className="patient-list">
          {filteredPatients.length > 0 ? (
            <table className="patient-table">
              <thead>
                <tr>
                  <th className="bold-header">ID</th>
                  <th className="bold-header">Time</th>
                  <th className="bold-header">Location</th>
                  <th className="bold-header">Complaint</th>
                  <th className="bold-header">Triage</th>
                  <th className="bold-header">Disposition</th>
                  <th className="bold-header">Destination</th>
                  <th className="bold-header">Tag #</th>
                  <th className="bold-header">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredPatients.map((patient) => (
                  <tr key={patient.patientId} className="patient-row">
                    <td>{patient.patientId}</td>
                    <td>{patient.timeAdded}</td>
                    <td>{patient.location || '-'}</td>
                    <td>{patient.complaint}</td>
                    <td>
                      <span 
                        className="triage-indicator"
                        style={{ backgroundColor: getTriageColor(patient.triageLevel) }}
                      >
                        {patient.triageLevel.toUpperCase()}
                      </span>
                    </td>
                    <td>{patient.disposition}</td>
                    <td>{patient.hospitalDestination || '-'}</td>
                    <td>{patient.triageTagNumber || '-'}</td>
                    <td>
                      <div className="action-buttons">
                        <button 
                          className="edit-btn"
                          onClick={() => handleEdit(patient)}
                        >
                          <PencilIcon width={14} height={14} />
                        </button>
                        <button 
                          className="delete-btn"
                          onClick={() => handleDelete(patient.patientId)}
                        >
                          <TrashIcon width={14} height={14} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="empty-state">
              No patients match the selected filters
            </div>
          )}
        </div>
      </div>

      {/* Edit Patient Modal */}
      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Edit Patient</h3>
              <button 
                className="close-btn"
                onClick={() => {
                  setShowEditModal(false);
                  setEditingPatient(null);
                }}
              >
                <XMarkIcon width={20} height={20} />
              </button>
            </div>
            <form onSubmit={handleSaveEdit} className="edit-patient-form">
              <div className="form-group">
                <label>Patient ID</label>
                <div className="patient-id-display">
                  {editingPatient.patientId}
                </div>
              </div>

              <div className="form-group">
                <label>Time Call Received</label>
                <input
                  type="time"
                  value={editingPatient.timeAdded}
                  onChange={(e) => {
                    const oldTime = editingPatient.timeAdded;
                    const newTime = e.target.value;
                    setEditingPatient({
                      ...editingPatient,
                      timeAdded: newTime
                    });
                    trackChange('Time Call Received', oldTime, newTime);
                  }}
                  className="time-input"
                />
              </div>

              <div className="form-group">
                <label>Location</label>
                <input
                  type="text"
                  value={editingPatient.location || ''}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    location: e.target.value
                  })}
                  placeholder="Enter patient location"
                />
              </div>

              <div className="form-group">
                <label>Chief Complaint</label>
                <input
                  type="text"
                  value={editingPatient.complaint}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    complaint: e.target.value
                  })}
                  required
                />
              </div>

              <div className="form-group">
                <label>Triage Level</label>
                <select
                  value={editingPatient.triageLevel}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    triageLevel: e.target.value
                  })}
                  required
                >
                  <option value="red">Red</option>
                  <option value="yellow">Yellow</option>
                  <option value="green">Green</option>
                  <option value="black">Black</option>
                </select>
              </div>

              <div className="form-group">
                <label>Disposition</label>
                <select
                  value={editingPatient.disposition}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    disposition: e.target.value,
                    hospitalDestination: e.target.value !== 'Transport' ? null : editingPatient.hospitalDestination
                  })}
                  required
                >
                  <option value="Transport">Transport</option>
                  <option value="Refusal">Refusal</option>
                  <option value="DOA">DOA</option>
                  <option value="Treatment Complete">Treatment Complete</option>
                </select>
              </div>

              {editingPatient.disposition === 'Transport' && (
                <div className="form-group">
                  <label>Hospital Destination</label>
                  <select
                    value={editingPatient.hospitalDestination || ''}
                    onChange={(e) => setEditingPatient({
                      ...editingPatient,
                      hospitalDestination: e.target.value
                    })}
                    required
                  >
                    <option value="">Select hospital...</option>
                    <option value="County General">County General</option>
                    <option value="Mercy Hospital">Mercy Hospital</option>
                    <option value="St. Mary's">St. Mary's</option>
                    <option value="VA Medical Center">VA Medical Center</option>
                  </select>
                </div>
              )}

              <div className="form-group">
                <label>Notes</label>
                <textarea
                  value={editingPatient.notes || ''}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    notes: e.target.value
                  })}
                  placeholder="Enter any additional notes"
                  rows={4}
                  className="patient-notes"
                />
              </div>

              <div className="form-group">
                <label>Patient Triage Tag Number</label>
                <input
                  type="text"
                  value={editingPatient.triageTagNumber || ''}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    triageTagNumber: e.target.value
                  })}
                  placeholder="Enter triage tag number"
                />
              </div>

              <div className="form-group">
                <label>Related Triage Tag Numbers</label>
                <input
                  type="text"
                  value={editingPatient.relatedTags || ''}
                  onChange={(e) => setEditingPatient({
                    ...editingPatient,
                    relatedTags: e.target.value
                  })}
                  placeholder="Enter related tag numbers (comma separated)"
                />
              </div>

              {/* Add Change History section before the action buttons */}
              <div className="change-history">
                <h4>Change History</h4>
                <div className="changes-list">
                  {changes.length > 0 ? (
                    changes.map((change, index) => (
                      <div key={index} className="change-entry">
                        <div className="change-info">
                          <span className="change-field">{change.field}</span>
                          <span className="change-arrow">���</span>
                          <span className="change-value">{change.newValue}</span>
                        </div>
                        <div className="change-meta">
                          <span className="change-user">{change.user}</span>
                          <span className="change-time">{change.timestamp}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-changes">No changes recorded</div>
                  )}
                </div>
              </div>

              <div className="modal-actions">
                <button type="submit" className="save-btn">
                  Save Changes
                </button>
                <button 
                  type="button" 
                  className="cancel-btn"
                  onClick={() => {
                    setShowEditModal(false);
                    setEditingPatient(null);
                    setChanges([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientTracking; 