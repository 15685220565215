import React, { useState } from 'react';
import { ChevronLeftIcon, PlusIcon, PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function RoleManagement() {
  const [permissions] = useState(['view', 'create', 'edit', 'delete']);
  const [modules] = useState([
    { id: 'dashboard', name: 'Dashboard' },
    { id: 'incidents', name: 'Incidents' },
    { id: 'resources', name: 'Resources' },
    { id: 'communications', name: 'Communications' },
    { id: 'reports', name: 'Reports' },
    { id: 'users', name: 'User Management' },
    { id: 'settings', name: 'Settings' },
    { id: 'audit', name: 'Audit Log' }
  ]);

  const [roles, setRoles] = useState([
    {
      id: 1,
      name: 'Administrator',
      description: 'Full system access',
      permissions: {
        dashboard: ['view'],
        incidents: ['view', 'create', 'edit', 'delete'],
        resources: ['view', 'create', 'edit', 'delete'],
        communications: ['view', 'create', 'edit', 'delete'],
        reports: ['view', 'create', 'edit', 'delete'],
        users: ['view', 'create', 'edit', 'delete'],
        settings: ['view', 'edit'],
        audit: ['view']
      }
    },
    {
      id: 2,
      name: 'Supervisor',
      description: 'Department oversight',
      permissions: {
        dashboard: ['view'],
        incidents: ['view', 'create', 'edit'],
        resources: ['view', 'create', 'edit'],
        communications: ['view', 'create', 'edit'],
        reports: ['view', 'create', 'edit'],
        users: ['view'],
        settings: ['view'],
        audit: ['view']
      }
    },
    {
      id: 3,
      name: 'User',
      description: 'Standard user access',
      permissions: {
        dashboard: ['view'],
        incidents: ['view', 'create'],
        resources: ['view', 'create'],
        communications: ['view', 'create'],
        reports: ['view', 'create'],
        users: [],
        settings: ['view'],
        audit: []
      }
    },
    {
      id: 4,
      name: 'View Only',
      description: 'Read-only access',
      permissions: {
        dashboard: ['view'],
        incidents: ['view'],
        resources: ['view'],
        communications: ['view'],
        reports: ['view'],
        users: [],
        settings: [],
        audit: []
      }
    }
  ]);
  const [editingRole, setEditingRole] = useState(null);
  const [editedPermissions, setEditedPermissions] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newRole, setNewRole] = useState({
    name: '',
    description: '',
    permissions: {
      dashboard: [],
      incidents: [],
      resources: [],
      communications: [],
      reports: [],
      users: [],
      settings: [],
      audit: []
    }
  });

  const handleEditClick = (role) => {
    setEditingRole(role);
    setEditedPermissions({...role.permissions});
  };

  const handleSaveEdit = () => {
    setRoles(roles.map(role => 
      role.id === editingRole.id 
        ? {...role, permissions: editedPermissions}
        : role
    ));
    setEditingRole(null);
  };

  const handleCancelEdit = () => {
    setEditingRole(null);
    setEditedPermissions({});
  };

  const togglePermission = (moduleId, permission) => {
    setEditedPermissions(prev => {
      const currentPerms = [...(prev[moduleId] || [])];
      const index = currentPerms.indexOf(permission);
      
      if (index === -1) {
        currentPerms.push(permission);
      } else {
        currentPerms.splice(index, 1);
      }

      return {
        ...prev,
        [moduleId]: currentPerms
      };
    });
  };

  const handleCreateRole = () => {
    if (newRole.name.trim()) {
      const roleToAdd = {
        id: Math.max(...roles.map(r => r.id)) + 1,
        ...newRole
      };
      setRoles([...roles, roleToAdd]);
      setShowCreateModal(false);
      setNewRole({
        name: '',
        description: '',
        permissions: {
          dashboard: [],
          incidents: [],
          resources: [],
          communications: [],
          reports: [],
          users: [],
          settings: [],
          audit: []
        }
      });
    }
  };

  const toggleNewRolePermission = (moduleId, permission) => {
    setNewRole(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [moduleId]: prev.permissions[moduleId].includes(permission)
          ? prev.permissions[moduleId].filter(p => p !== permission)
          : [...prev.permissions[moduleId], permission]
      }
    }));
  };

  return (
    <div className="role-management-page">
      <div className="page-header">
        <div className="header-content">
          <h1>Role Management</h1>
          <p className="header-description">Configure user roles and permissions</p>
        </div>
        <div className="header-actions">
          <Link to="/admin" className="back-btn">
            <ChevronLeftIcon className="btn-icon" />
            Back to Admin
          </Link>
          <button 
            className="create-btn"
            onClick={() => setShowCreateModal(true)}
          >
            <PlusIcon className="btn-icon" />
            Create Role
          </button>
        </div>
      </div>

      <div className="roles-grid">
        {roles.map((role) => (
          <div key={role.id} className="role-card">
            <div className="role-header">
              <div className="role-info">
                <h3>{role.name}</h3>
                <p>{role.description}</p>
              </div>
              <div className="role-actions">
                <button 
                  className="icon-btn" 
                  style={{ background: '#228be615', color: '#228be6' }}
                  onClick={() => handleEditClick(role)}
                >
                  <PencilIcon className="btn-icon" />
                </button>
                <button className="icon-btn" style={{ background: '#fa525215', color: '#fa5252' }}>
                  <TrashIcon className="btn-icon" />
                </button>
              </div>
            </div>
            
            <div className="permissions-table-container">
              <table className="permissions-table">
                <thead>
                  <tr>
                    <th>Module</th>
                    {permissions.map(perm => (
                      <th key={perm}>{perm}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {modules.map(module => (
                    <tr key={module.id}>
                      <td>{module.name}</td>
                      {permissions.map(perm => (
                        <td key={`${module.id}-${perm}`}>
                          {editingRole?.id === role.id ? (
                            <div 
                              className={`permission-checkbox ${
                                editedPermissions[module.id]?.includes(perm) ? 'active' : 'inactive'
                              }`}
                              onClick={() => togglePermission(module.id, perm)}
                            />
                          ) : (
                            <div className={`permission-indicator ${
                              role.permissions[module.id]?.includes(perm) ? 'active' : 'inactive'
                            }`} />
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              
              {editingRole?.id === role.id && (
                <div className="edit-actions">
                  <button 
                    className="save-btn"
                    onClick={handleSaveEdit}
                  >
                    Save Changes
                  </button>
                  <button 
                    className="cancel-btn"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Create Role Modal */}
      {showCreateModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Create New Role</h2>
              <button 
                className="close-btn"
                onClick={() => setShowCreateModal(false)}
              >
                <XMarkIcon className="btn-icon" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Role Name</label>
                <input
                  type="text"
                  value={newRole.name}
                  onChange={(e) => setNewRole({...newRole, name: e.target.value})}
                  placeholder="Enter role name"
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  value={newRole.description}
                  onChange={(e) => setNewRole({...newRole, description: e.target.value})}
                  placeholder="Enter role description"
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Permissions</label>
                <table className="permissions-table">
                  <thead>
                    <tr>
                      <th>Module</th>
                      {permissions.map(perm => (
                        <th key={perm}>{perm}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {modules.map(module => (
                      <tr key={module.id}>
                        <td>{module.name}</td>
                        {permissions.map(perm => (
                          <td key={`${module.id}-${perm}`}>
                            <div 
                              className={`permission-checkbox ${
                                newRole.permissions[module.id]?.includes(perm) ? 'active' : 'inactive'
                              }`}
                              onClick={() => toggleNewRolePermission(module.id, perm)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button 
                className="cancel-btn"
                onClick={() => setShowCreateModal(false)}
              >
                Cancel
              </button>
              <button 
                className="save-btn"
                onClick={handleCreateRole}
              >
                Create Role
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoleManagement; 