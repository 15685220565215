import React, { createContext, useContext, useState } from 'react';

const EventLogContext = createContext();

export function EventLogProvider({ children }) {
  const [eventLog, setEventLog] = useState([]);

  const addEventLogEntry = (entry) => {
    setEventLog(prev => [...prev, entry]);
  };

  return (
    <EventLogContext.Provider value={{ eventLog, addEventLogEntry }}>
      {children}
    </EventLogContext.Provider>
  );
}

export function useEventLog() {
  return useContext(EventLogContext);
} 