import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeftIcon, 
  BellAlertIcon,
} from '@heroicons/react/24/outline';
import './EmergencyAlerts.css';

function EmergencyAlerts() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    affectedAreas: [],
    expirationTime: '',
    notificationChannels: {
      email: false,
      sms: false,
      push: false,
      radio: false
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      notificationChannels: {
        ...prev.notificationChannels,
        [name]: checked
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your submit logic here
    console.log('Alert Data:', formData);
    // Navigate back to communications after successful submission
    navigate('/communications');
  };

  return (
    <div className="emergency-alerts-container">
      <div className="alerts-header">
        <button className="alerts-back-button" onClick={() => navigate('/communications')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Communications
        </button>
        <div className="alerts-header-content">
          <div className="alerts-title">
            <BellAlertIcon className="alerts-icon" />
            <div>
              <h1>Create Emergency Alert</h1>
              <p>Send a new emergency alert to all affected areas</p>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="alerts-form">
        <div className="form-section">
          <h2>Alert Details</h2>
          
          <div className="form-group">
            <label htmlFor="title">Alert Title*</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="Enter alert title"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description*</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              placeholder="Provide detailed information about the emergency"
              rows="4"
            />
          </div>

          <div className="form-group">
            <label htmlFor="expirationTime">Expiration Time*</label>
            <input
              type="datetime-local"
              id="expirationTime"
              name="expirationTime"
              value={formData.expirationTime}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="form-section">
          <h2>Notification Channels</h2>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="email"
                checked={formData.notificationChannels.email}
                onChange={handleCheckboxChange}
              />
              Email Notifications
            </label>
            <label>
              <input
                type="checkbox"
                name="sms"
                checked={formData.notificationChannels.sms}
                onChange={handleCheckboxChange}
              />
              SMS Alerts
            </label>
            <label>
              <input
                type="checkbox"
                name="push"
                checked={formData.notificationChannels.push}
                onChange={handleCheckboxChange}
              />
              Push Notifications
            </label>
            <label>
              <input
                type="checkbox"
                name="radio"
                checked={formData.notificationChannels.radio}
                onChange={handleCheckboxChange}
              />
              Radio Broadcast
            </label>
          </div>
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            className="cancel-button"
            onClick={() => navigate('/communications')}
          >
            Cancel
          </button>
          <button type="submit" className="submit-button">
            Send Emergency Alert
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmergencyAlerts; 