import React, { useState } from 'react';
import {
  ChartBarIcon,
  TruckIcon,
  MapIcon,
  ArrowTrendingUpIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';

function Analytics() {
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    startTime: '00:00',
    endDate: new Date().toISOString().split('T')[0],
    endTime: '23:59'
  });

  const analyticsSections = [
    {
      title: 'Incident Analytics',
      icon: <ChartBarIcon className="section-icon" />,
      items: [
        { name: 'Incident Types', description: 'Distribution of incidents by type', trend: '+12% this month' },
        { name: 'Patient Counts', description: 'Number of patients per incident', trend: '+5% this month' },
        { name: 'Triage Levels', description: 'Distribution of triage categories', trend: 'Stable' },
        { name: 'Dispositions', description: 'Patient care outcomes and decisions', trend: '-3% this month' },
        { name: 'Transport Destinations', description: 'Hospital and facility destinations', trend: '+7% this month' }
      ]
    },
    {
      title: 'Resource Utilization',
      icon: <TruckIcon className="section-icon" />,
      items: [
        { name: 'Equipment Usage', description: 'Resource allocation and utilization rates', trend: '+15% this month' },
        { name: 'Vehicle Status', description: 'Active and available vehicle statistics', trend: 'Stable' },
        { name: 'Resource Distribution', description: 'Geographic distribution of resources', trend: '+3% this month' }
      ]
    },
    {
      title: 'Geographic Data',
      icon: <MapIcon className="section-icon" />,
      items: [
        { name: 'Incident Hotspots', description: 'High-frequency incident locations', trend: '+8% this month' },
        { name: 'Coverage Analysis', description: 'Service area coverage statistics', trend: 'Stable' },
      ]
    }
  ];

  const handleTimeFilterChange = (e) => {
    if (e.target.value === 'custom') {
      setShowCustomDate(true);
    } else {
      setShowCustomDate(false);
    }
  };

  const handleDateTimeChange = (e) => {
    setDateRange({
      ...dateRange,
      [e.target.name]: e.target.value
    });
  };

  const handleApplyRange = () => {
    console.log('Applied range:', {
      start: `${dateRange.startDate}T${dateRange.startTime}`,
      end: `${dateRange.endDate}T${dateRange.endTime}`
    });
    setShowCustomDate(false);
  };

  return (
    <div className="analytics-page">
      <div className="page-header">
        <h1>Analytics</h1>
        <div className="header-actions">
          <div className="filter-container">
            <select 
              className="time-filter"
              onChange={handleTimeFilterChange}
              defaultValue="day"
            >
              <option value="day">Last 24 Hours</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
              <option value="custom">Custom Range</option>
            </select>
            
            {showCustomDate && (
              <div className="custom-date-filter">
                <div className="datetime-input-group">
                  <label>From:</label>
                  <div className="datetime-inputs">
                    <input
                      type="date"
                      name="startDate"
                      value={dateRange.startDate}
                      onChange={handleDateTimeChange}
                      className="date-input"
                    />
                    <input
                      type="time"
                      name="startTime"
                      value={dateRange.startTime}
                      onChange={handleDateTimeChange}
                      className="time-input"
                    />
                  </div>
                </div>
                <div className="datetime-input-group">
                  <label>To:</label>
                  <div className="datetime-inputs">
                    <input
                      type="date"
                      name="endDate"
                      value={dateRange.endDate}
                      onChange={handleDateTimeChange}
                      className="date-input"
                    />
                    <input
                      type="time"
                      name="endTime"
                      value={dateRange.endTime}
                      onChange={handleDateTimeChange}
                      className="time-input"
                    />
                  </div>
                </div>
                <div className="filter-actions">
                  <button className="cancel-filter-btn" onClick={() => setShowCustomDate(false)}>
                    Cancel
                  </button>
                  <button className="apply-filter-btn" onClick={handleApplyRange}>
                    Apply Range
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="analytics-grid">
        {analyticsSections.map((section, index) => (
          <div key={index} className="analytics-section">
            <div className="section-header">
              {section.icon}
              <h2>{section.title}</h2>
            </div>
            <div className="section-content">
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="analytics-item">
                  <div className="item-header">
                    <h3>{item.name}</h3>
                    <span className="trend-indicator">
                      <ArrowTrendingUpIcon className="trend-icon" />
                      {item.trend}
                    </span>
                  </div>
                  <p>{item.description}</p>
                  <button className="view-btn">
                    View Data
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Analytics; 