import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function Preferences() {
  const navigate = useNavigate();
  const [preferences, setPreferences] = useState({
    refreshRate: '30',
    defaultView: 'list',
    autoRefresh: true,
    confirmActions: true,
    showTooltips: true
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Preferences saved:', preferences);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Preferences</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="preferences-section">
            <h3>System Preferences</h3>
            
            <div className="form-group">
              <label>Auto-Refresh Interval</label>
              <select
                value={preferences.refreshRate}
                onChange={(e) => setPreferences({...preferences, refreshRate: e.target.value})}
                className="compact-input"
              >
                <option value="15">15 seconds</option>
                <option value="30">30 seconds</option>
                <option value="60">1 minute</option>
                <option value="300">5 minutes</option>
              </select>
            </div>

            <div className="form-group">
              <label>Default View</label>
              <select
                value={preferences.defaultView}
                onChange={(e) => setPreferences({...preferences, defaultView: e.target.value})}
                className="compact-input"
              >
                <option value="list">List View</option>
                <option value="grid">Grid View</option>
                <option value="map">Map View</option>
              </select>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={preferences.autoRefresh}
                  onChange={(e) => setPreferences({...preferences, autoRefresh: e.target.checked})}
                />
                Enable Auto-Refresh
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={preferences.confirmActions}
                  onChange={(e) => setPreferences({...preferences, confirmActions: e.target.checked})}
                />
                Confirm Before Important Actions
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={preferences.showTooltips}
                  onChange={(e) => setPreferences({...preferences, showTooltips: e.target.checked})}
                />
                Show Feature Tooltips
              </label>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Preferences
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Preferences; 