import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

function CreateUserModal({ isOpen, onClose, user, onSubmit }) {
  const [userForm, setUserForm] = useState({
    name: user?.name || '',
    email: user?.email || '',
    role: user?.role || 'Staff',
    status: user?.status || 'Active',
    password: '',
    confirmPassword: ''
  });

  // Define role capabilities
  const roleCapabilities = {
    'Administrator': [
      'Full system access',
      'User management',
      'System configuration',
      'All emergency features'
    ],
    'Emergency Manager': [
      'Emergency alerts',
      'Incident management',
      'Resource allocation',
      'Team coordination'
    ],
    'Staff': [
      'View alerts',
      'Basic reporting',
      'Team communication',
      'Resource requests'
    ]
  };

  useEffect(() => {
    if (user) {
      setUserForm({
        name: user.name,
        email: user.email,
        role: user.role,
        status: user.status,
        password: '',
        confirmPassword: ''
      });
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user && userForm.password !== userForm.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    onSubmit(userForm);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="user-form-modal">
        <div className="modal-container">
          <div className="modal-header">
            <h2>{user ? 'Edit User' : 'Create New User'}</h2>
            <button className="close-button" onClick={onClose}>
              <XMarkIcon width={20} height={20} />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="modal-form">
            <div className="form-row">
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  value={userForm.name}
                  onChange={(e) => setUserForm({...userForm, name: e.target.value})}
                  placeholder="Enter full name"
                  required
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={userForm.email}
                  onChange={(e) => setUserForm({...userForm, email: e.target.value})}
                  placeholder="Enter email address"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label>Role</label>
              <select
                value={userForm.role}
                onChange={(e) => setUserForm({...userForm, role: e.target.value})}
              >
                <option value="Administrator">Administrator</option>
                <option value="Emergency Manager">Emergency Manager</option>
                <option value="Staff">Staff</option>
              </select>
              <div className="role-capabilities">
                <label>Role Capabilities:</label>
                <ul>
                  {roleCapabilities[userForm.role].map((capability, index) => (
                    <li key={index}>{capability}</li>
                  ))}
                </ul>
              </div>
            </div>

            {!user && (
              <div className="form-row">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    value={userForm.password}
                    onChange={(e) => setUserForm({...userForm, password: e.target.value})}
                    placeholder="Enter password"
                    required={!user}
                  />
                </div>

                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    value={userForm.confirmPassword}
                    onChange={(e) => setUserForm({...userForm, confirmPassword: e.target.value})}
                    placeholder="Confirm password"
                    required={!user}
                  />
                </div>
              </div>
            )}

            <div className="modal-actions">
              <button type="button" className="cancel-btn" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="submit-btn" style={{ 
                backgroundColor: '#228be620',
                color: '#228be6',
                borderColor: '#228be6'
              }}>
                {user ? 'Update User' : 'Create User'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUserModal; 