import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  ArrowLeftIcon,
  DocumentTextIcon,
  DocumentChartBarIcon,
  ClipboardDocumentListIcon,
  CloudArrowDownIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/outline';

function ViewReport() {
  const { id } = useParams();
  const [report] = useState({
    id: id,
    title: 'Daily Incident Summary',
    type: 'Incident Report',
    author: 'John Smith',
    created: '2024-02-20 17:00',
    status: 'Published',
    incident: 'Multiple',
    period: 'Feb 20, 2024',
    summary: 'Summary of incidents and responses across all units for February 20, 2024.',
    content: {
      overview: 'Total of 12 incidents responded to during the 24-hour period.',
      details: [
        {
          section: 'Emergency Responses',
          data: 'Responded to 8 medical emergencies, 2 fire alarms, 1 structure fire, and 1 vehicle accident.'
        },
        {
          section: 'Resource Utilization',
          data: 'All units maintained operational status throughout the period. Engine 5 required minor maintenance.'
        },
        {
          section: 'Notable Events',
          data: 'Structure fire at 123 Main St required multi-unit response. Situation contained within 45 minutes.'
        }
      ]
    },
    attachments: [
      { id: 1, name: 'incident-stats.pdf', type: 'PDF', size: '2.4 MB' },
      { id: 2, name: 'response-times.xlsx', type: 'Excel', size: '1.1 MB' },
      { id: 3, name: 'scene-photos.zip', type: 'ZIP', size: '15.8 MB' }
    ]
  });

  const getTypeIcon = (type) => {
    switch (type) {
      case 'Incident Report':
        return <DocumentTextIcon className="type-icon incident" />;
      case 'Resource Report':
        return <DocumentChartBarIcon className="type-icon resource" />;
      case 'Activity Report':
        return <ClipboardDocumentListIcon className="type-icon activity" />;
      default:
        return null;
    }
  };

  return (
    <div className="view-report-page">
      <div className="page-header">
        <div className="header-title">
          <h1>{report.title}</h1>
        </div>
        <div className="header-actions">
          <Link to="/reports" className="back-btn">
            <ChevronLeftIcon className="btn-icon" />
            Back to Reports
          </Link>
          <Link to={`/reports/edit/${id}`} className="edit-btn">
            Edit Report
          </Link>
        </div>
      </div>

      <div className="report-detail-container">
        <div className="report-header">
          <div className="report-meta">
            <div className="type-container">
              {getTypeIcon(report.type)}
              <span className="type-label">{report.type}</span>
            </div>
            <span className={`status-badge ${report.status.toLowerCase()}`}>
              {report.status}
            </span>
          </div>
          
          <div className="report-info">
            <div className="info-row">
              <span className="label">Author:</span>
              <span className="value">{report.author}</span>
            </div>
            <div className="info-row">
              <span className="label">Created:</span>
              <span className="value">{report.created}</span>
            </div>
            <div className="info-row">
              <span className="label">Period:</span>
              <span className="value">{report.period}</span>
            </div>
            <div className="info-row">
              <span className="label">Incident:</span>
              <span className="value">{report.incident}</span>
            </div>
          </div>
        </div>

        <div className="report-content">
          <div className="report-summary">
            <h3>Summary</h3>
            <p>{report.summary}</p>
          </div>

          <div className="report-details">
            {report.content.details.map((detail, index) => (
              <div key={index} className="detail-section">
                <h3>{detail.section}</h3>
                <p>{detail.data}</p>
              </div>
            ))}
          </div>

          <div className="attachments-section">
            <h3>Attachments</h3>
            <div className="attachments-list">
              {report.attachments.map(attachment => (
                <div key={attachment.id} className="attachment-item">
                  <div className="attachment-info">
                    <span className="attachment-name">{attachment.name}</span>
                    <span className="attachment-size">{attachment.size}</span>
                  </div>
                  <button className="download-btn">
                    <CloudArrowDownIcon className="btn-icon" />
                    Download
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewReport; 