import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function EditPersonnel() {
  const { id } = useParams();
  const [person, setPerson] = useState({
    id: id,
    name: 'John Smith',
    status: 'On Duty',
    assignment: 'Command Unit 1',
    badge: '12345',
    phone: '(555) 123-4567',
    email: 'john.smith@emergency.com',
    role: 'Paramedic',
    certifications: ['EMT-P', 'ACLS', 'PALS'],
    notes: []
  });

  const [newNote, setNewNote] = useState('');

  const addNote = (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    const note = {
      id: Date.now(),
      text: newNote,
      timestamp: new Date().toISOString(),
      initials: 'JS' // Should come from user context
    };

    setPerson(prev => ({
      ...prev,
      notes: [note, ...prev.notes]
    }));
    setNewNote('');
  };

  return (
    <div className="edit-personnel-page">
      <div className="page-header">
        <h1>Edit Personnel</h1>
        <div className="header-actions">
          <span className="badge-number">Badge #{person.badge}</span>
          <Link to="/personnel" className="nav-link">
            Back to Personnel List
          </Link>
        </div>
      </div>

      <div className="personnel-detail-container">
        <div className="personnel-info-section">
          <h2>Personnel Information</h2>
          <div className="detail-grid">
            <div className="detail-item">
              <label>Name</label>
              <input
                type="text"
                value={person.name}
                onChange={(e) => setPerson({...person, name: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Status</label>
              <select
                value={person.status}
                onChange={(e) => setPerson({...person, status: e.target.value})}
              >
                <option value="On Duty">On Duty</option>
                <option value="On Call">On Call</option>
                <option value="Off Duty">Off Duty</option>
                <option value="Out of Service">Out of Service</option>
              </select>
            </div>

            <div className="detail-item">
              <label>Current Assignment</label>
              <input
                type="text"
                value={person.assignment}
                onChange={(e) => setPerson({...person, assignment: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Badge Number</label>
              <input
                type="text"
                value={person.badge}
                onChange={(e) => setPerson({...person, badge: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Phone</label>
              <input
                type="tel"
                value={person.phone}
                onChange={(e) => setPerson({...person, phone: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Email</label>
              <input
                type="email"
                value={person.email}
                onChange={(e) => setPerson({...person, email: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Role</label>
              <select
                value={person.role}
                onChange={(e) => setPerson({...person, role: e.target.value})}
              >
                <option value="Paramedic">Paramedic</option>
                <option value="EMT">EMT</option>
                <option value="Firefighter">Firefighter</option>
                <option value="Police">Police</option>
                <option value="Dispatcher">Dispatcher</option>
                <option value="Supervisor">Supervisor</option>
              </select>
            </div>

            <div className="detail-item">
              <label>Certifications</label>
              <div className="certifications-list">
                {person.certifications.map((cert, index) => (
                  <span key={index} className="certification-badge">
                    {cert}
                    <button
                      className="remove-cert-btn"
                      onClick={() => setPerson(prev => ({
                        ...prev,
                        certifications: prev.certifications.filter((_, i) => i !== index)
                      }))}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="personnel-notes-section">
          <h2>Notes</h2>
          <form onSubmit={addNote} className="note-form">
            <div className="note-input-container">
              <input
                type="text"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Add a note..."
                className="note-input"
              />
              <button type="submit" className="add-note-btn">
                Add Note
              </button>
            </div>
          </form>

          <div className="notes-list">
            {person.notes.map(note => (
              <div key={note.id} className="note-item">
                <div className="note-header">
                  <span className="note-time">
                    {new Date(note.timestamp).toLocaleTimeString()}
                  </span>
                  <span className="note-initials">{note.initials}</span>
                </div>
                <div className="note-text">{note.text}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="form-actions">
          <button className="submit-btn">Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default EditPersonnel; 