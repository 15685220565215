import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  UsersIcon, 
  Cog6ToothIcon, 
  DocumentChartBarIcon,
  ShieldCheckIcon,
  BellIcon,
  ClockIcon,
  ListBulletIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

function Admin() {
  const navigate = useNavigate();

  const adminModules = [
    {
      id: 'users',
      name: 'User Management',
      description: 'Manage user accounts, roles, and permissions',
      icon: <UsersIcon className="module-icon" />,
      color: '#228be6',
      stats: {
        total: '156',
        label: 'Active Users'
      }
    },
    {
      id: 'settings',
      name: 'System Settings',
      description: 'Configure system-wide settings and preferences',
      icon: <Cog6ToothIcon className="module-icon" />,
      color: '#40c057',
      stats: {
        total: '24',
        label: 'Configurations'
      }
    },
    {
      id: 'reports',
      name: 'Reports & Analytics',
      description: 'View system analytics and generate reports',
      icon: <DocumentChartBarIcon className="module-icon" />,
      color: '#fab005',
      stats: {
        total: '2.4k',
        label: 'Reports'
      }
    },
    {
      id: 'security',
      name: 'Security Settings',
      description: 'Manage security policies and access controls',
      icon: <ShieldCheckIcon className="module-icon" />,
      color: '#fa5252',
      stats: {
        total: '98%',
        label: 'Security Score'
      }
    },
    {
      id: 'alerts',
      name: 'Alert Management',
      description: 'Configure and manage system alerts',
      icon: <BellIcon className="module-icon" />,
      color: '#be4bdb',
      stats: {
        total: '18',
        label: 'Active Alerts'
      }
    },
    {
      id: 'audit',
      name: 'Audit Logs',
      description: 'View system audit trails and activity logs',
      icon: <ClockIcon className="module-icon" />,
      color: '#15aabf',
      stats: {
        total: '847',
        label: 'Events Today'
      }
    }
  ];

  return (
    <div className="page-container">
      <div className="page-header">
        <h1>Admin Dashboard</h1>
        <p className="header-description">Manage system settings and user access</p>
      </div>

      <div className="admin-modules-grid">
        {adminModules.map((module) => (
          <div
            key={module.id}
            className="admin-module-card"
            onClick={() => navigate(`/admin/${module.id}`)}
          >
            <div className="module-header">
              <div className="module-icon-wrapper" style={{ 
                backgroundColor: `${module.color}15`,
                color: module.color 
              }}>
                {module.icon}
              </div>
              <div className="module-stats">
                <div className="stat-value" style={{ color: module.color }}>
                  {module.stats.total}
                </div>
                <div className="stat-label">{module.stats.label}</div>
              </div>
            </div>
            <div className="module-content">
              <h3 style={{ color: module.color }}>{module.name}</h3>
              <p>{module.description}</p>
            </div>
          </div>
        ))}

        <div
          className="admin-module-card"
          onClick={() => navigate('/admin/roles')}
          style={{ cursor: 'pointer' }}
        >
          <div className="module-header">
            <div className="module-icon-wrapper" style={{ 
              backgroundColor: '#748ffc15',
              color: '#748ffc'
            }}>
              <UserGroupIcon className="module-icon" />
            </div>
            <div className="module-stats">
              <div className="stat-value" style={{ color: '#748ffc' }}>
                Roles
              </div>
              <div className="stat-label">Access Control</div>
            </div>
          </div>
          <div className="module-content">
            <h3 style={{ color: '#748ffc' }}>Role Management</h3>
            <p>Configure user roles and access permissions</p>
          </div>
        </div>

        <div
          className="admin-module-card"
          onClick={() => navigate('/admin/lists')}
        >
          <div className="module-header">
            <div className="module-icon-wrapper" style={{ 
              backgroundColor: '#228be615',
              color: '#228be6'
            }}>
              <ListBulletIcon className="module-icon" />
            </div>
            <div className="module-stats">
              <div className="stat-value" style={{ color: '#228be6' }}>
                Lists
              </div>
              <div className="stat-label">Configuration</div>
            </div>
          </div>
          <div className="module-content">
            <h3 style={{ color: '#228be6' }}>System List Manager</h3>
            <p>Manage and configure system lists and options</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin; 