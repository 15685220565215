import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Incidents from './pages/Incidents';
import Personnel from './pages/Personnel';
import Resources from './pages/Resources';
import Communications from './pages/Communications';
import Reports from './pages/Reports';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import IncidentDetails from './pages/IncidentDetails';
import PatientTracking from './pages/PatientTracking';
import PatientDetail from './pages/PatientDetail';
import EditPersonnel from './pages/EditPersonnel';
import EditResource from './pages/EditResource';
import './App.css';
import { EventLogProvider } from './contexts/EventLogContext';
import ViewCommunication from './pages/ViewCommunication';
import ReplyCommunication from './pages/ReplyCommunication';
import ViewReport from './pages/ViewReport';
import EditReport from './pages/EditReport';
import NewReport from './pages/NewReport';
import PersonalInformation from './pages/configurations/PersonalInformation';
import Theme from './pages/configurations/Theme';
import Admin from './pages/Admin';
import PasswordSecurity from './pages/configurations/PasswordSecurity';
import Preferences from './pages/configurations/Preferences';
import AlertSettings from './pages/configurations/AlertSettings';
import EmailNotifications from './pages/configurations/EmailNotifications';
import MobileNotifications from './pages/configurations/MobileNotifications';
import EmergencyAlerts from './pages/communications/EmergencyAlerts';
import Announcements from './pages/communications/Announcements';
import UserManagement from './pages/admin/UserManagement';
import SystemSettings from './pages/admin/SystemSettings';
import Lists from './pages/Lists';
import AuditLog from './pages/admin/AuditLog';
import RoleManagement from './pages/admin/RoleManagement';
import CommunicationsSettings from './pages/configurations/Communications';
import { ListsProvider } from './contexts/ListsContext';

function App() {
  return (
    <ListsProvider>
      <EventLogProvider>
        <Router>
          <div className="app-container">
            <Sidebar />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/incidents" element={<Incidents />} />
                <Route path="/incidents/:id" element={<IncidentDetails />} />
                <Route path="/incidents/:id/patients" element={<PatientTracking />} />
                <Route path="/incidents/:id/patients/:prid" element={<PatientDetail />} />
                <Route path="/personnel" element={<Personnel />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/communications" element={<Communications />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/personnel/edit/:id" element={<EditPersonnel />} />
                <Route path="/resources/edit/:id" element={<EditResource />} />
                <Route path="/communications/view/:id" element={<ViewCommunication />} />
                <Route path="/communications/reply/:id" element={<ReplyCommunication />} />
                <Route path="/reports/view/:id" element={<ViewReport />} />
                <Route path="/reports/edit/:id" element={<EditReport />} />
                <Route path="/reports/new" element={<NewReport />} />
                <Route path="/settings/personal-information" element={<PersonalInformation />} />
                <Route path="/settings/password-security" element={<PasswordSecurity />} />
                <Route path="/settings/preferences" element={<Preferences />} />
                <Route path="/settings/alerts" element={<AlertSettings />} />
                <Route path="/settings/email-notifications" element={<EmailNotifications />} />
                <Route path="/settings/mobile-notifications" element={<MobileNotifications />} />
                <Route path="/settings/theme" element={<Theme />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/users" element={<UserManagement />} />
                <Route path="/admin/settings" element={<SystemSettings />} />
                <Route path="/communications/alerts" element={<EmergencyAlerts />} />
                <Route path="/communications/announcements" element={<Announcements />} />
                <Route path="/admin/lists" element={<Lists />} />
                <Route path="/admin/audit" element={<AuditLog />} />
                <Route path="/admin/roles" element={<RoleManagement />} />
                <Route path="/settings/communications" element={<CommunicationsSettings />} />
              </Routes>
            </main>
          </div>
        </Router>
      </EventLogProvider>
    </ListsProvider>
  );
}

export default App; 