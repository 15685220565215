import React from 'react';
import { 
  ExclamationTriangleIcon,
  UserGroupIcon,
  TruckIcon
} from '@heroicons/react/24/outline';

export function Dashboard() {
  // Sample data - replace with real data later
  const triageData = [
    { label: 'Critical', count: 5, color: '#fa5252' },
    { label: 'Urgent', count: 8, color: '#fab005' },
    { label: 'Non-Urgent', count: 12, color: '#40c057' }
  ];

  const dispositionData = [
    { label: 'Transport', count: 15, color: '#228be6' },
    { label: 'Refusal', count: 7, color: '#be4bdb' },
    { label: 'Cancelled', count: 3, color: '#868e96' }
  ];

  const hospitalData = [
    { label: 'City General', count: 8, color: '#15aabf' },
    { label: 'Memorial', count: 5, color: '#7950f2' },
    { label: 'County', count: 4, color: '#e64980' }
  ];

  // Data organized by specific incident
  const incidentData = {
    "Incident #2024-001": {
      severity: {
        title: 'Patient Severity',
        data: [
          { label: 'Critical', count: 2, color: '#fa5252' },
          { label: 'Serious', count: 1, color: '#fab005' },
          { label: 'Stable', count: 1, color: '#40c057' }
        ]
      },
      disposition: {
        title: 'Disposition',
        data: [
          { label: 'Transport', count: 3, color: '#228be6' },
          { label: 'Refusal', count: 1, color: '#be4bdb' }
        ]
      },
      hospital: {
        title: 'Hospital Destination',
        data: [
          { label: 'City General', count: 2, color: '#15aabf' },
          { label: 'Memorial', count: 1, color: '#7950f2' }
        ]
      },
      events: [
        {
          time: "14:22",
          type: "Dispatch",
          description: "Units dispatched to scene",
          unit: "E-31"
        },
        {
          time: "14:25",
          type: "On Scene",
          description: "First unit arrived on scene",
          unit: "E-31"
        },
        {
          time: "14:30",
          type: "Patient Contact",
          description: "Patient assessment initiated",
          unit: "E-31"
        },
        {
          time: "14:45",
          type: "Transport",
          description: "Patient transport initiated to City General",
          unit: "A-42"
        }
      ]
    },
    "Incident #2024-002": {
      severity: {
        title: 'Patient Severity',
        data: [
          { label: 'Critical', count: 1, color: '#fa5252' },
          { label: 'Stable', count: 2, color: '#40c057' }
        ]
      },
      disposition: {
        title: 'Disposition',
        data: [
          { label: 'Transport', count: 2, color: '#228be6' },
          { label: 'Refusal', count: 1, color: '#be4bdb' }
        ]
      },
      hospital: {
        title: 'Hospital Destination',
        data: [
          { label: 'County', count: 2, color: '#e64980' }
        ]
      },
      events: [
        {
          time: "15:10",
          type: "Dispatch",
          description: "Multiple units dispatched",
          unit: "E-32"
        },
        {
          time: "15:15",
          type: "On Scene",
          description: "Units arrived, established command",
          unit: "E-32"
        },
        {
          time: "15:20",
          type: "Triage",
          description: "Multiple patients identified",
          unit: "A-41"
        },
        {
          time: "15:35",
          type: "Transport",
          description: "First patient transport initiated",
          unit: "A-41"
        }
      ]
    }
  };

  const renderBarChart = (data, title) => {
    const total = data.reduce((sum, item) => sum + item.count, 0);
    const maxCount = Math.max(...data.map(item => item.count));
    
    return (
      <div className="chart-card">
        <div className="chart-header">
          <h2>{title}</h2>
          <span className="total-count">Total: {total}</span>
        </div>
        <div className="chart-body">
          {data.map((item, index) => (
            <div key={index} className="chart-row">
              <div className="chart-label">{item.label}</div>
              <div className="chart-bar-container">
                <div 
                  className="chart-bar" 
                  style={{
                    width: `${(item.count / maxCount) * 100}%`,
                    backgroundColor: item.color
                  }}
                />
                <span className="chart-value">{item.count}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEventTable = (events) => {
    return (
      <div className="events-table-container">
        <h3>Significant Events</h3>
        <table className="events-table">
          <thead>
            <tr>
              <th>Time</th>
              <th>Type</th>
              <th>Unit</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.time}</td>
                <td>
                  <span className={`event-type ${event.type.toLowerCase()}`}>
                    {event.type}
                  </span>
                </td>
                <td>{event.unit}</td>
                <td>{event.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="dashboard">
      <div className="page-header">
        <h1>Dashboard</h1>
      </div>
      
      <div className="dashboard-grid">
        <div className="stat-card">
          <div className="stat-card-content">
            <div className="stat-header">
              <ExclamationTriangleIcon className="card-icon" style={{ color: '#fa5252' }} />
              <h2>Active Incidents</h2>
            </div>
            <div className="stat-body">
              <div className="stat-number">3</div>
              <div className="stat-trend negative">+1 in last hour</div>
            </div>
          </div>
        </div>
        
        <div className="stat-card">
          <div className="stat-card-content">
            <div className="stat-header">
              <UserGroupIcon className="card-icon" style={{ color: '#228be6' }} />
              <h2>Total Patients</h2>
            </div>
            <div className="stat-body">
              <div className="stat-number">8</div>
              <div className="stat-trend positive">+3 from yesterday</div>
            </div>
          </div>
        </div>
        
        <div className="stat-card">
          <div className="stat-card-content">
            <div className="stat-header">
              <TruckIcon className="card-icon" style={{ color: '#40c057' }} />
              <h2>Resources Assigned</h2>
            </div>
            <div className="stat-body">
              <div className="stat-number">5</div>
              <div className="stat-trend neutral">No change</div>
            </div>
          </div>
        </div>
      </div>

      <div className="incidents-breakdown">
        <h2 className="section-title">Incident Breakdown</h2>
        {Object.entries(incidentData).map(([incidentId, data]) => (
          <div key={incidentId} className="incident-section">
            <div className="incident-header">
              <h2>{incidentId}</h2>
            </div>
            <div className="charts-grid">
              {renderBarChart(data.severity.data, data.severity.title)}
              {renderBarChart(data.disposition.data, data.disposition.title)}
              {renderBarChart(data.hospital.data, data.hospital.title)}
            </div>
            {renderEventTable(data.events)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard; 