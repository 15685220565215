import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  ChevronLeftIcon,
  PaperClipIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

function NewReport() {
  const navigate = useNavigate();
  const [report, setReport] = useState({
    title: '',
    type: 'Incident Report',
    author: 'John Smith', // This would normally come from user context
    status: 'Draft',
    incident: '',
    period: new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }),
    summary: '',
    content: {
      overview: '',
      details: [
        {
          section: 'Emergency Responses',
          data: ''
        },
        {
          section: 'Resource Utilization',
          data: ''
        },
        {
          section: 'Notable Events',
          data: ''
        }
      ]
    }
  });

  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle report creation
    console.log('Report created:', report);
    // Navigate back to reports page after creation
    navigate('/reports');
  };

  return (
    <div className="new-report-page">
      <div className="page-header">
        <div className="header-title">
          <h1>New Report</h1>
        </div>
      </div>

      <div className="report-form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                value={report.title}
                onChange={(e) => setReport({...report, title: e.target.value})}
                placeholder="Enter report title"
                required
              />
            </div>

            <div className="form-group">
              <label>Type</label>
              <select
                value={report.type}
                onChange={(e) => setReport({...report, type: e.target.value})}
                required
              >
                <option value="Incident Report">Incident Report</option>
                <option value="Resource Report">Resource Report</option>
                <option value="Activity Report">Activity Report</option>
              </select>
            </div>

            <div className="form-group">
              <label>Status</label>
              <select
                value={report.status}
                onChange={(e) => setReport({...report, status: e.target.value})}
              >
                <option value="Draft">Draft</option>
                <option value="Under Review">Under Review</option>
                <option value="Published">Published</option>
              </select>
            </div>

            <div className="form-group">
              <label>Incident</label>
              <input
                type="text"
                value={report.incident}
                onChange={(e) => setReport({...report, incident: e.target.value})}
                placeholder="Enter incident reference"
              />
            </div>

            <div className="form-group">
              <label>Period</label>
              <input
                type="text"
                value={report.period}
                onChange={(e) => setReport({...report, period: e.target.value})}
                placeholder="Enter report period"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Summary</label>
            <textarea
              value={report.summary}
              onChange={(e) => setReport({...report, summary: e.target.value})}
              rows={3}
              placeholder="Enter report summary"
              required
            />
          </div>

          <div className="form-group">
            <label>Overview</label>
            <textarea
              value={report.content.overview}
              onChange={(e) => setReport({
                ...report,
                content: {...report.content, overview: e.target.value}
              })}
              rows={3}
              placeholder="Enter report overview"
            />
          </div>

          {report.content.details.map((detail, index) => (
            <div key={index} className="detail-section-edit">
              <div className="form-group">
                <label>{detail.section}</label>
                <textarea
                  value={detail.data}
                  onChange={(e) => {
                    const newDetails = [...report.content.details];
                    newDetails[index].data = e.target.value;
                    setReport({
                      ...report,
                      content: {...report.content, details: newDetails}
                    });
                  }}
                  rows={3}
                  placeholder={`Enter ${detail.section.toLowerCase()} details`}
                />
              </div>
            </div>
          ))}

          <div className="attachments-section">
            <label>Attachments</label>
            <div className="file-upload">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                id="file-input"
                className="hidden-input"
              />
              <label htmlFor="file-input" className="file-upload-btn">
                <PaperClipIcon className="btn-icon" />
                Add Attachment
              </label>
            </div>

            {files.length > 0 && (
              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <button
                      type="button"
                      className="remove-file-btn"
                      onClick={() => removeFile(index)}
                    >
                      <XMarkIcon className="btn-icon" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="form-actions">
            <Link to="/reports" className="cancel-btn">
              <ChevronLeftIcon className="btn-icon" />
              Back to Reports
            </Link>
            <button type="submit" className="submit-btn">
              Create Report
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewReport; 