import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function MobileNotifications() {
  const navigate = useNavigate();
  const [mobileSettings, setMobileSettings] = useState({
    pushEnabled: true,
    vibrationEnabled: true,
    soundEnabled: true,
    bannerStyle: 'persistent',
    notifications: {
      incidents: true,
      patientUpdates: true,
      systemAlerts: true,
      statusChanges: true,
      assignments: true,
      locationUpdates: true
    },
    quietHours: {
      enabled: false,
      start: '22:00',
      end: '07:00'
    },
    devicePreferences: {
      allowBackgroundRefresh: true,
      dataUsage: 'optimized',
      batteryOptimization: true,
      locationTracking: false
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Mobile settings saved:', mobileSettings);
    // TODO: Save to backend
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Mobile Notifications</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="preferences-section">
            <h3>General Settings</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.pushEnabled}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    pushEnabled: e.target.checked
                  })}
                />
                Enable Push Notifications
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.vibrationEnabled}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    vibrationEnabled: e.target.checked
                  })}
                />
                Enable Vibration
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.soundEnabled}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    soundEnabled: e.target.checked
                  })}
                />
                Enable Notification Sounds
              </label>
            </div>

            <div className="form-group">
              <label>Banner Style</label>
              <select
                value={mobileSettings.bannerStyle}
                onChange={(e) => setMobileSettings({
                  ...mobileSettings,
                  bannerStyle: e.target.value
                })}
                className="compact-input"
              >
                <option value="temporary">Temporary</option>
                <option value="persistent">Persistent</option>
                <option value="minimal">Minimal</option>
              </select>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Notification Types</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.incidents}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      incidents: e.target.checked
                    }
                  })}
                />
                Incident Updates
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.patientUpdates}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      patientUpdates: e.target.checked
                    }
                  })}
                />
                Patient Updates
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.systemAlerts}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      systemAlerts: e.target.checked
                    }
                  })}
                />
                System Alerts
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.statusChanges}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      statusChanges: e.target.checked
                    }
                  })}
                />
                Status Changes
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.assignments}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      assignments: e.target.checked
                    }
                  })}
                />
                Task Assignments
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.notifications.locationUpdates}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    notifications: {
                      ...mobileSettings.notifications,
                      locationUpdates: e.target.checked
                    }
                  })}
                />
                Location Updates
              </label>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Device Preferences</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.devicePreferences.allowBackgroundRefresh}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    devicePreferences: {
                      ...mobileSettings.devicePreferences,
                      allowBackgroundRefresh: e.target.checked
                    }
                  })}
                />
                Allow Background Refresh
              </label>
            </div>

            <div className="form-group">
              <label>Data Usage</label>
              <select
                value={mobileSettings.devicePreferences.dataUsage}
                onChange={(e) => setMobileSettings({
                  ...mobileSettings,
                  devicePreferences: {
                    ...mobileSettings.devicePreferences,
                    dataUsage: e.target.value
                  }
                })}
                className="compact-input"
              >
                <option value="minimal">Minimal</option>
                <option value="optimized">Optimized</option>
                <option value="unrestricted">Unrestricted</option>
              </select>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.devicePreferences.batteryOptimization}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    devicePreferences: {
                      ...mobileSettings.devicePreferences,
                      batteryOptimization: e.target.checked
                    }
                  })}
                />
                Enable Battery Optimization
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.devicePreferences.locationTracking}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    devicePreferences: {
                      ...mobileSettings.devicePreferences,
                      locationTracking: e.target.checked
                    }
                  })}
                />
                Enable Location Tracking
              </label>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Quiet Hours</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={mobileSettings.quietHours.enabled}
                  onChange={(e) => setMobileSettings({
                    ...mobileSettings,
                    quietHours: {
                      ...mobileSettings.quietHours,
                      enabled: e.target.checked
                    }
                  })}
                />
                Enable Quiet Hours
              </label>
            </div>

            {mobileSettings.quietHours.enabled && (
              <div className="form-row">
                <div className="form-group half">
                  <label>Start Time</label>
                  <input
                    type="time"
                    value={mobileSettings.quietHours.start}
                    onChange={(e) => setMobileSettings({
                      ...mobileSettings,
                      quietHours: {
                        ...mobileSettings.quietHours,
                        start: e.target.value
                      }
                    })}
                    className="compact-input"
                  />
                </div>
                <div className="form-group half">
                  <label>End Time</label>
                  <input
                    type="time"
                    value={mobileSettings.quietHours.end}
                    onChange={(e) => setMobileSettings({
                      ...mobileSettings,
                      quietHours: {
                        ...mobileSettings.quietHours,
                        end: e.target.value
                      }
                    })}
                    className="compact-input"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Settings
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MobileNotifications; 