import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

function AddResourceModal({ isOpen, onClose, onSubmit, initialData, isEditing = false, editingResource }) {
  const [formData, setFormData] = useState({
    unitId: '',
    type: 'Engine',
    status: 'Available'
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="add-resource-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{isEditing ? 'Edit Resource' : 'Add New Resource'}</h2>
            <button className="modal-close" onClick={onClose}>
              <XMarkIcon width={20} height={20} />
            </button>
          </div>
          
          <form onSubmit={handleSubmit} className="modal-form">
            <div className="modal-form-group unit-id">
              <label>Unit ID</label>
              <input
                type="text"
                name="unitId"
                value={formData.unitId}
                onChange={(e) => setFormData({...formData, unitId: e.target.value})}
                placeholder="E-31"
                required
              />
            </div>

            <div className="modal-form-group type">
              <label>Type</label>
              <select
                value={formData.type}
                onChange={(e) => setFormData({...formData, type: e.target.value})}
              >
                <option value="Engine">Engine</option>
                <option value="Truck">Truck</option>
                <option value="Ambulance">Ambulance</option>
                <option value="Command">Command</option>
              </select>
            </div>

            <div className="modal-form-group status">
              <label>Unit Status</label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({...formData, status: e.target.value})}
              >
                <option value="Available">Available</option>
                <option value="Responding">Responding</option>
                <option value="On Scene">On Scene</option>
                <option value="Out of Service">Out of Service</option>
              </select>
            </div>

            <div className="modal-actions">
              <button type="button" className="modal-button cancel" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="modal-button submit">
                {isEditing ? 'Update Resource' : 'Add Resource'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddResourceModal; 