import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  MapPinIcon,
  UserGroupIcon,
  TruckIcon,
  PlusIcon,
  ClockIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  FunnelIcon,
  StarIcon as StarOutline
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import PatientTracking from './PatientTracking';

export function IncidentDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { incident } = location.state || {};
  
  // Mock data - replace with API data
  const incidentData = {
    masterNumber: "2024-001",
    name: "Mass Casualty Incident - Highway 101",
    location: "Highway 101 at Main Street",
    type: "Mass Casualty",
    emsBranchDirector: "Capt. Johnson",
    patientCounts: {
      red: 3,
      yellow: 5,
      green: 8,
      black: 1,
      total: 17
    },
    dispositions: {
      transported: 12,
      refusal: 3,
      pending: 2
    },
    hospitalDestinations: {
      "City General": 5,
      "County Medical": 4,
      "Valley Hospital": 3
    },
    assignedResources: [
      { unit: "E-31", type: "Engine", status: "On Scene", time: "14:30" },
      { unit: "M-42", type: "Medic", status: "On Scene", time: "14:35" },
      { unit: "R-51", type: "Rescue", status: "On Scene", time: "14:40" }
    ],
    requestedResources: [
      { unit: "Type 1 Engine", quantity: 2, status: "Pending", time: "14:45" },
      { unit: "ALS Ambulance", quantity: 3, status: "En Route", time: "14:50" }
    ],
    eventLog: [
      { time: "14:30", message: "E-31 arrived on scene", userInitials: "JD" },
      { time: "14:35", message: "First patient contact made", userInitials: "MK" },
      { time: "14:40", message: "EMS Branch established", userInitials: "RB" }
    ]
  };

  const [showAddPatient, setShowAddPatient] = useState(false);
  const [timeSort, setTimeSort] = useState('desc');
  const [showSignificantOnly, setShowSignificantOnly] = useState(false);
  const [eventLog, setEventLog] = useState(incidentData.eventLog);
  const [newEvent, setNewEvent] = useState({
    message: '',
    isSignificant: false
  });

  const [assignedResources, setAssignedResources] = useState(incidentData.assignedResources || []);
  const [requestedResources, setRequestedResources] = useState(incidentData.requestedResources || []);
  const [newAssignedResource, setNewAssignedResource] = useState({
    unit: '',
    status: ''
  });

  const [newRequestedResource, setNewRequestedResource] = useState({
    unit: '',
    status: ''
  });

  const [newPatient, setNewPatient] = useState({
    triageLevel: '',
    complaint: '',
    disposition: '',
    hospitalDestination: ''
  });

  const [patientCount, setPatientCount] = useState(
    (incidentData.patients?.length || 0) + 1
  );

  const calculatePercentage = (value, total) => {
    return total > 0 ? Math.round((value / total) * 100) : 0;
  };

  const toggleSignificant = (index) => {
    const eventToUpdate = filteredEvents[index]; // Get the event from filtered list
    
    // Find the actual index in the full event log
    const actualIndex = eventLog.findIndex(e => 
      e.time === eventToUpdate.time && 
      e.message === eventToUpdate.message &&
      e.userInitials === eventToUpdate.userInitials
    );

    if (actualIndex !== -1) {
      setEventLog(prevLog => prevLog.map((event, i) => {
        if (i === actualIndex) {
          return {
            ...event,
            isSignificant: !event.isSignificant
          };
        }
        return event;
      }));
    }
  };

  const sortedEvents = [...eventLog].sort((a, b) => {
    const timeA = new Date(`2024-01-01 ${a.time}`);
    const timeB = new Date(`2024-01-01 ${b.time}`);
    return timeSort === 'asc' ? timeA - timeB : timeB - timeA;
  });

  const filteredEvents = showSignificantOnly 
    ? sortedEvents.filter(event => event.isSignificant)
    : sortedEvents;

  const handleEventSubmit = (e) => {
    e.preventDefault();
    if (!newEvent.message.trim()) return;

    const newEventEntry = {
      time: new Date().toLocaleTimeString('en-US', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit'
      }),
      message: newEvent.message.trim(),
      isSignificant: newEvent.isSignificant,
      userInitials: "JD" // This would come from the logged-in user
    };

    setEventLog(prevLog => [newEventEntry, ...prevLog]);
    
    setNewEvent({
      message: '',
      isSignificant: false
    });
  };

  const handleAddResource = (e) => {
    e.preventDefault();
    if (!newAssignedResource.unit.trim()) return;

    const resourceEntry = {
      unit: newAssignedResource.unit.trim(),
      status: newAssignedResource.status,
      time: new Date().toLocaleTimeString('en-US', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit'
      })
    };

    setAssignedResources(prev => [...prev, resourceEntry]);
    setNewAssignedResource({ unit: '', status: '' });
  };

  const moveToAssigned = (index) => {
    const resource = requestedResources[index];
    const currentTime = new Date().toLocaleTimeString('en-US', { 
      hour12: false, 
      hour: '2-digit', 
      minute: '2-digit'
    });

    // Remove from requested
    setRequestedResources(prev => prev.filter((_, i) => i !== index));

    // Add to assigned with 'On Scene' status
    const assignedResource = {
      ...resource,
      status: 'On Scene',
      time: currentTime
    };
    setAssignedResources(prev => [...prev, assignedResource]);

    // Add to event log
    const eventEntry = {
      time: currentTime,
      message: `${resource.unit} moved from requested to assigned resources - Status: On Scene`,
      userInitials: "JD", // Replace with actual user initials
      isSignificant: false
    };
    setEventLog(prev => [eventEntry, ...prev]);
  };

  const removeResource = (index, isRequested) => {
    if (isRequested) {
      setRequestedResources(prev => prev.filter((_, i) => i !== index));
    } else {
      setAssignedResources(prev => prev.filter((_, i) => i !== index));
    }
  };

  const handleAddPatient = (e) => {
    e.preventDefault();
    
    const patientData = {
      patientId: `${incidentData.masterNumber}-${patientCount.toString().padStart(2, '0')}`,
      triageLevel: newPatient.triageLevel,
      complaint: newPatient.complaint,
      disposition: newPatient.disposition,
      hospitalDestination: newPatient.disposition === 'Transport' ? newPatient.hospitalDestination : null,
      timeAdded: new Date().toLocaleTimeString('en-US', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit'
      })
    };

    // Add to patients list (in real app, this would be an API call)
    console.log('Adding patient:', patientData);

    // Increment patient count
    setPatientCount(prevCount => prevCount + 1);

    // Reset form
    setNewPatient({
      triageLevel: '',
      complaint: '',
      disposition: '',
      hospitalDestination: ''
    });

    // Close form
    setShowAddPatient(false);
  };

  const handleAddAssignedResource = (e) => {
    e.preventDefault();
    if (!newAssignedResource.unit.trim()) return;

    const currentTime = new Date().toLocaleTimeString('en-US', { 
      hour12: false, 
      hour: '2-digit', 
      minute: '2-digit'
    });

    const resourceEntry = {
      unit: newAssignedResource.unit.trim(),
      status: newAssignedResource.status,
      time: currentTime
    };

    // Add to assigned resources
    setAssignedResources(prev => [...prev, resourceEntry]);

    // Add to event log
    const eventEntry = {
      time: currentTime,
      message: `${resourceEntry.unit} assigned - Status: ${resourceEntry.status}`,
      userInitials: "JD", // Replace with actual user initials
      isSignificant: false
    };
    setEventLog(prev => [eventEntry, ...prev]);

    setNewAssignedResource({ unit: '', status: '' });
  };

  const handleAddRequestedResource = (e) => {
    e.preventDefault();
    if (!newRequestedResource.unit.trim()) return;

    const currentTime = new Date().toLocaleTimeString('en-US', { 
      hour12: false, 
      hour: '2-digit', 
      minute: '2-digit'
    });

    const resourceEntry = {
      unit: newRequestedResource.unit.trim(),
      status: newRequestedResource.status,
      time: currentTime
    };

    // Add to requested resources
    setRequestedResources(prev => [...prev, resourceEntry]);

    // Add to event log
    const eventEntry = {
      time: currentTime,
      message: `${resourceEntry.unit} requested - Status: ${resourceEntry.status}`,
      userInitials: "JD", // Replace with actual user initials
      isSignificant: false
    };
    setEventLog(prev => [eventEntry, ...prev]);

    // Clear the form
    setNewRequestedResource({ unit: '', status: '' });
  };

  return (
    <div className="incident-details">
      <div className="page-header">
        <button 
          className="back-button"
          onClick={() => navigate('/incidents')}
        >
          <ArrowLeftIcon className="btn-icon" width={14} height={14} />
          Back to Incidents
        </button>
      </div>

      <div className="incident-layout">
        <div className="incident-left-column">
          {/* Incident Overview Card */}
          <div className="detail-card incident-overview">
            <h2>Incident Overview</h2>
            <div className="overview-content">
              <div className="overview-item">
                <label>Incident Number</label>
                <span className="incident-number">{incidentData.masterNumber}</span>
              </div>
              
              <div className="overview-item">
                <label>Incident Name</label>
                <span className="incident-name">{incidentData.name}</span>
              </div>
              
              <div className="overview-item">
                <div className="with-icon">
                  <MapPinIcon width={14} height={14} className="item-icon" />
                  <label>Location</label>
                </div>
                <span className="location">{incidentData.location}</span>
              </div>
              
              <div className="overview-item">
                <label>Incident Type</label>
                <span className="incident-type">{incidentData.type}</span>
              </div>
              
              <div className="overview-item">
                <div className="with-icon">
                  <UserGroupIcon width={14} height={14} className="item-icon" />
                  <label>EMS Branch Director</label>
                </div>
                <span className="commander">{incidentData.emsBranchDirector}</span>
              </div>
            </div>
          </div>

          {/* Patient Statistics Card */}
          <div className="detail-card patient-stats">
            <h2>Patient Statistics</h2>
            
            <div className="stats-grid">
              <div className="stats-section">
                <h3>Triage Levels</h3>
                <div className="progress-list">
                  <div className="progress-item">
                    <div className="progress-label">
                      <span>Red</span>
                      <div className="progress-numbers">
                        <span className="count">{incidentData.patientCounts.red}</span>
                        <span className="percentage">
                          {calculatePercentage(incidentData.patientCounts.red, incidentData.patientCounts.total)}%
                        </span>
                      </div>
                    </div>
                    <div className="progress-bar-bg">
                      <div 
                        className="progress-bar red"
                        style={{ 
                          width: `${calculatePercentage(incidentData.patientCounts.red, incidentData.patientCounts.total)}%` 
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="progress-item">
                    <div className="progress-label">
                      <span>Yellow</span>
                      <div className="progress-numbers">
                        <span className="count">{incidentData.patientCounts.yellow}</span>
                        <span className="percentage">
                          {calculatePercentage(incidentData.patientCounts.yellow, incidentData.patientCounts.total)}%
                        </span>
                      </div>
                    </div>
                    <div className="progress-bar-bg">
                      <div 
                        className="progress-bar yellow"
                        style={{ 
                          width: `${calculatePercentage(incidentData.patientCounts.yellow, incidentData.patientCounts.total)}%` 
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="progress-item">
                    <div className="progress-label">
                      <span>Green</span>
                      <div className="progress-numbers">
                        <span className="count">{incidentData.patientCounts.green}</span>
                        <span className="percentage">
                          {calculatePercentage(incidentData.patientCounts.green, incidentData.patientCounts.total)}%
                        </span>
                      </div>
                    </div>
                    <div className="progress-bar-bg">
                      <div 
                        className="progress-bar green"
                        style={{ 
                          width: `${calculatePercentage(incidentData.patientCounts.green, incidentData.patientCounts.total)}%` 
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="progress-item">
                    <div className="progress-label">
                      <span>Black</span>
                      <div className="progress-numbers">
                        <span className="count">{incidentData.patientCounts.black}</span>
                        <span className="percentage">
                          {calculatePercentage(incidentData.patientCounts.black, incidentData.patientCounts.total)}%
                        </span>
                      </div>
                    </div>
                    <div className="progress-bar-bg">
                      <div 
                        className="progress-bar black"
                        style={{ 
                          width: `${calculatePercentage(incidentData.patientCounts.black, incidentData.patientCounts.total)}%` 
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="total-patients">
                    Total Patients: {incidentData.patientCounts.total}
                  </div>
                </div>
              </div>

              <div className="stats-section">
                <h3>Dispositions</h3>
                <div className="progress-list">
                  {Object.entries(incidentData.dispositions).map(([key, value]) => (
                    <div key={key} className="progress-item">
                      <div className="progress-label">
                        <span>{key}</span>
                        <div className="progress-numbers">
                          <span className="count">{value}</span>
                          <span className="percentage">
                            {calculatePercentage(value, incidentData.patientCounts.total)}%
                          </span>
                        </div>
                      </div>
                      <div className="progress-bar-bg">
                        <div 
                          className="progress-bar blue"
                          style={{ 
                            width: `${calculatePercentage(value, incidentData.patientCounts.total)}%` 
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="stats-section">
                <h3>Hospital Destinations</h3>
                <div className="progress-list">
                  {Object.entries(incidentData.hospitalDestinations).map(([hospital, count]) => (
                    <div key={hospital} className="progress-item">
                      <div className="progress-label">
                        <span>{hospital}</span>
                        <div className="progress-numbers">
                          <span className="count">{count}</span>
                          <span className="percentage">
                            {calculatePercentage(count, incidentData.patientCounts.total)}%
                          </span>
                        </div>
                      </div>
                      <div className="progress-bar-bg">
                        <div 
                          className="progress-bar blue"
                          style={{ 
                            width: `${calculatePercentage(count, incidentData.patientCounts.total)}%` 
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Assigned Resources */}
          <div className="detail-card resource-card">
            <h2>Assigned Resources</h2>
            <div className="resource-form">
              <form onSubmit={handleAddAssignedResource}>
                <div className="form-row">
                  <input
                    type="text"
                    placeholder="Unit name or type..."
                    value={newAssignedResource.unit}
                    onChange={(e) => setNewAssignedResource({
                      ...newAssignedResource,
                      unit: e.target.value
                    })}
                  />
                  <select
                    value={newAssignedResource.status}
                    onChange={(e) => setNewAssignedResource({
                      ...newAssignedResource,
                      status: e.target.value
                    })}
                  >
                    <option value="">Select status...</option>
                    <option value="On Scene">On Scene</option>
                    <option value="En Route">En Route</option>
                    <option value="Available">Available</option>
                    <option value="Out of Service">Out of Service</option>
                  </select>
                  <button 
                    type="submit"
                    className="add-resource-btn"
                    disabled={!newAssignedResource.unit.trim() || !newAssignedResource.status}
                  >
                    <PlusIcon width={14} height={14} />
                    Add
                  </button>
                </div>
              </form>
            </div>
            <div className="resource-list">
              {assignedResources.map((resource, index) => (
                <div key={index} className="resource-item">
                  <div className="resource-info">
                    <div className="resource-unit">
                      <TruckIcon width={14} height={14} />
                      {resource.unit}
                    </div>
                    <div className="resource-status">{resource.status}</div>
                  </div>
                  <div className="resource-actions">
                    <span className="resource-time">{resource.time}</span>
                    <button 
                      className="remove-btn"
                      onClick={() => removeResource(index, false)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Requested Resources */}
          <div className="detail-card resource-card">
            <h2>Requested Resources</h2>
            <div className="resource-form">
              <form onSubmit={handleAddRequestedResource}>
                <div className="form-row">
                  <input
                    type="text"
                    placeholder="Unit name or type..."
                    value={newRequestedResource.unit}
                    onChange={(e) => setNewRequestedResource({
                      ...newRequestedResource,
                      unit: e.target.value
                    })}
                  />
                  <select
                    value={newRequestedResource.status}
                    onChange={(e) => setNewRequestedResource({
                      ...newRequestedResource,
                      status: e.target.value
                    })}
                  >
                    <option value="">Select status...</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Denied">Denied</option>
                  </select>
                  <button 
                    type="submit"
                    className="add-resource-btn"
                    disabled={!newRequestedResource.unit.trim() || !newRequestedResource.status}
                  >
                    <PlusIcon width={14} height={14} />
                    Add
                  </button>
                </div>
              </form>
            </div>
            <div className="resource-list">
              {requestedResources.map((resource, index) => (
                <div key={index} className="resource-item">
                  <div className="resource-info">
                    <div className="resource-unit">
                      <TruckIcon width={14} height={14} />
                      {resource.unit}
                    </div>
                    <div className="resource-status">{resource.status}</div>
                  </div>
                  <div className="resource-actions">
                    <span className="resource-time">{resource.time}</span>
                    <button 
                      className="assign-btn"
                      onClick={() => moveToAssigned(index)}
                    >
                      Assign
                    </button>
                    <button 
                      className="remove-btn"
                      onClick={() => removeResource(index, true)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="incident-right-column">
          {/* Add Patient Form */}
          <div className="detail-card">
            <div className="card-header">
              <div className="header-left" onClick={() => setShowAddPatient(!showAddPatient)}>
                <h2>Add Patient</h2>
                {showAddPatient ? 
                  <ChevronUpIcon width={14} height={14} className="header-icon" /> : 
                  <ChevronDownIcon width={14} height={14} className="header-icon" />
                }
              </div>
              <button 
                className="patient-tracking-btn"
                onClick={() => navigate(`/incidents/${incidentData.masterNumber}/patients`)}
              >
                Patient Tracking
              </button>
            </div>
            
            {showAddPatient && (
              <form onSubmit={handleAddPatient} className="add-patient-form">
                <div className="form-group">
                  <label>Patient ID</label>
                  <div className="patient-id-display">
                    {`${incidentData.masterNumber}-${patientCount.toString().padStart(2, '0')}`}
                  </div>
                </div>

                <div className="form-group">
                  <label>Triage Level</label>
                  <select
                    value={newPatient.triageLevel}
                    onChange={(e) => setNewPatient({
                      ...newPatient,
                      triageLevel: e.target.value
                    })}
                    required
                  >
                    <option value="">Select triage level...</option>
                    <option value="red">Red</option>
                    <option value="yellow">Yellow</option>
                    <option value="green">Green</option>
                    <option value="black">Black</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Chief Complaint</label>
                  <input
                    type="text"
                    value={newPatient.complaint}
                    onChange={(e) => setNewPatient({
                      ...newPatient,
                      complaint: e.target.value
                    })}
                    placeholder="Enter chief complaint"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Disposition</label>
                  <select
                    value={newPatient.disposition}
                    onChange={(e) => setNewPatient({
                      ...newPatient,
                      disposition: e.target.value
                    })}
                    required
                  >
                    <option value="">Select disposition...</option>
                    <option value="Transport">Transport</option>
                    <option value="Refusal">Refusal</option>
                    <option value="DOA">DOA</option>
                    <option value="Treatment Complete">Treatment Complete</option>
                  </select>
                </div>

                {newPatient.disposition === 'Transport' && (
                  <div className="form-group">
                    <label>Hospital Destination</label>
                    <select
                      value={newPatient.hospitalDestination}
                      onChange={(e) => setNewPatient({
                        ...newPatient,
                        hospitalDestination: e.target.value
                      })}
                      required
                    >
                      <option value="">Select hospital...</option>
                      <option value="County General">County General</option>
                      <option value="Mercy Hospital">Mercy Hospital</option>
                      <option value="St. Mary's">St. Mary's</option>
                      <option value="VA Medical Center">VA Medical Center</option>
                    </select>
                  </div>
                )}

                <div className="form-actions">
                  <button type="submit" className="submit-button">
                    Add Patient
                  </button>
                </div>
              </form>
            )}
          </div>

          {/* Event Log */}
          <div className="detail-card event-log">
            <div className="event-log-header">
              <h2>Event Log</h2>
              <div className="event-log-controls">
                <button 
                  className={`filter-button ${showSignificantOnly ? 'active' : ''}`}
                  onClick={() => setShowSignificantOnly(!showSignificantOnly)}
                >
                  <StarIcon width={14} height={14} />
                  {showSignificantOnly ? 'Show All' : 'Show Significant'}
                </button>
                <button 
                  className="sort-button"
                  onClick={() => setTimeSort(timeSort === 'asc' ? 'desc' : 'asc')}
                >
                  {timeSort === 'asc' ? (
                    <ChevronUpIcon width={14} height={14} />
                  ) : (
                    <ChevronDownIcon width={14} height={14} />
                  )}
                  Time
                </button>
              </div>
            </div>
            
            <div className="event-entry-form">
              <form onSubmit={handleEventSubmit}>
                <div className="form-content">
                  <input
                    type="text"
                    placeholder="Enter new event..."
                    value={newEvent.message}
                    onChange={(e) => setNewEvent({
                      ...newEvent,
                      message: e.target.value
                    })}
                    className="event-input"
                  />
                  <div className="form-actions">
                    <label className="significant-label">
                      <input
                        type="checkbox"
                        checked={newEvent.isSignificant}
                        onChange={(e) => setNewEvent({
                          ...newEvent,
                          isSignificant: e.target.checked
                        })}
                        className="significant-checkbox"
                      />
                      <span className="checkbox-text">
                        <StarIcon width={14} height={14} />
                        Significant
                      </span>
                    </label>
                    <button 
                      type="submit"
                      className="submit-button"
                      disabled={!newEvent.message.trim()}
                    >
                      Add Entry
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="table-container">
              <table className="event-table">
                <thead>
                  <tr>
                    <th className="significant-column"></th>
                    <th>Time</th>
                    <th>Details</th>
                    <th>By</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEvents.map((event, index) => (
                    <tr key={index} className={event.isSignificant ? 'significant' : ''}>
                      <td className="significant-column">
                        <button 
                          className="significant-button"
                          onClick={() => toggleSignificant(index)}
                        >
                          {event.isSignificant ? (
                            <StarIcon className="star-icon filled" width={14} height={14} />
                          ) : (
                            <StarOutline className="star-icon" width={14} height={14} />
                          )}
                        </button>
                      </td>
                      <td>
                        <div className="time-cell">
                          <ClockIcon width={14} height={14} />
                          {event.time}
                        </div>
                      </td>
                      <td>{event.message}</td>
                      <td className="user-initials">{event.userInitials}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncidentDetails; 