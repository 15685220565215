import React from 'react';
import { 
  XMarkIcon,
  UserCircleIcon,
  KeyIcon,
  ClockIcon
} from '@heroicons/react/24/outline';

function ViewUserModal({ isOpen, onClose, user }) {
  if (!isOpen || !user) return null;

  const roleCapabilities = {
    'Administrator': [
      'Full system access',
      'User management',
      'System configuration',
      'All emergency features'
    ],
    'Emergency Manager': [
      'Emergency alerts',
      'Incident management',
      'Resource allocation',
      'Team coordination'
    ],
    'Staff': [
      'View alerts',
      'Basic reporting',
      'Team communication',
      'Resource requests'
    ]
  };

  const getRoleColor = (role) => {
    switch (role) {
      case 'Administrator':
        return '#fa5252';
      case 'Emergency Manager':
        return '#40c057';
      case 'Staff':
        return '#228be6';
      default:
        return '#868e96';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return '#40c057';
      case 'Inactive':
        return '#868e96';
      case 'Suspended':
        return '#fa5252';
      default:
        return '#fab005';
    }
  };

  return (
    <div className="modal-overlay">
      <div className="user-view-modal">
        <div className="modal-container">
          <div className="modal-header">
            <div className="user-view-header">
              <div className="large-avatar">
                {user.avatar ? (
                  <img src={user.avatar} alt={user.name} />
                ) : (
                  <UserCircleIcon />
                )}
              </div>
              <div>
                <h2>{user.name}</h2>
                <span className="user-email">{user.email}</span>
              </div>
            </div>
            <button className="close-button" onClick={onClose}>
              <XMarkIcon width={20} height={20} />
            </button>
          </div>

          <div className="modal-content">
            <div className="user-info-grid">
              <div className="info-card">
                <div className="info-icon" style={{ backgroundColor: `${getRoleColor(user.role)}20` }}>
                  <KeyIcon style={{ color: getRoleColor(user.role) }} />
                </div>
                <div className="info-text">
                  <label>Role</label>
                  <span style={{ color: getRoleColor(user.role) }}>{user.role}</span>
                </div>
              </div>

              <div className="info-card">
                <div className="info-icon" style={{ backgroundColor: '#228be620' }}>
                  <ClockIcon style={{ color: '#228be6' }} />
                </div>
                <div className="info-text">
                  <label>Last Active</label>
                  <span>{user.lastActive}</span>
                </div>
              </div>
            </div>

            {user.permissions && (
              <div className="permissions-section">
                <h3>Permissions</h3>
                <div className="permissions-grid">
                  {Object.entries(user.permissions).map(([key, value]) => (
                    <div key={key} className="permission-item">
                      <input type="checkbox" checked={value} readOnly />
                      <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="modal-actions">
            <button className="cancel-btn" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUserModal; 