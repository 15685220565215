import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ExclamationTriangleIcon,
  ClockIcon,
  MapPinIcon,
  PlusIcon,
  PencilSquareIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

export function Incidents() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newIncident, setNewIncident] = useState({
    id: '',
    name: '',
    type: 'Medical Emergency',
    location: '',
    status: 'Active',
    startTime: '',
    notes: ''
  });

  const incidentData = [
    {
      id: "2024-001",
      name: "Cardiac Emergency",
      type: "Medical Emergency",
      location: "123 Main St",
      status: "Active",
      startTime: "14:30",
      duration: "45m"
    },
    {
      id: "2024-002",
      name: "Vehicle Collision",
      type: "Traffic Accident",
      location: "456 Oak Ave",
      status: "En Route",
      startTime: "15:15",
      duration: "20m"
    },
    {
      id: "2024-003",
      name: "Commercial Structure",
      type: "Structure Fire",
      location: "789 Pine St",
      status: "On Scene",
      startTime: "15:45",
      duration: "10m"
    }
  ];

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active': return '#40c057';
      case 'en route': return '#fab005';
      case 'on scene': return '#228be6';
      default: return '#868e96';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIncident(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddIncident = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Add incident to the list
    console.log('New incident:', newIncident);
    setIsModalOpen(false);
    setNewIncident({
      id: '',
      name: '',
      type: 'Medical Emergency',
      location: '',
      status: 'Active',
      startTime: '',
      notes: ''
    });
  };

  const handleViewDetails = (incident) => {
    navigate(`/incidents/${incident.id}`, { 
      state: { incident } 
    });
  };

  return (
    <div className="incidents">
      <div className="page-header">
        <h1>Incidents</h1>
        <button 
          className="add-button"
          onClick={handleAddIncident}
        >
          <PlusIcon className="btn-icon" />
          New Incident
        </button>
      </div>

      <div className="table-container">
        <table className="incidents-table">
          <thead>
            <tr>
              <th>ID / Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Location</th>
              <th>Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {incidentData.map((incident) => (
              <tr key={incident.id}>
                <td>
                  <div 
                    className="incident-id clickable"
                    onClick={() => handleViewDetails(incident)}
                  >
                    <ExclamationTriangleIcon className="table-icon" />
                    <div className="id-name-group">
                      <span className="incident-number">{incident.id}</span>
                      <span className="incident-name">{incident.name}</span>
                    </div>
                  </div>
                </td>
                <td>{incident.type}</td>
                <td>
                  <span 
                    className="status-badge"
                    style={{ 
                      backgroundColor: `${getStatusColor(incident.status)}20`,
                      color: getStatusColor(incident.status)
                    }}
                  >
                    {incident.status}
                  </span>
                </td>
                <td>
                  <div className="location-cell">
                    <MapPinIcon className="table-icon" />
                    {incident.location}
                  </div>
                </td>
                <td>
                  <div className="time-cell">
                    <ClockIcon className="table-icon" />
                    <div className="time-info">
                      <div>{incident.startTime}</div>
                      <div className="duration">{incident.duration}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <button 
                    className="view-button"
                    onClick={() => handleViewDetails(incident)}
                  >
                    <PencilSquareIcon className="btn-icon" />
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* New Incident Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h2>Create New Incident</h2>
              <button 
                className="close-btn"
                onClick={() => setIsModalOpen(false)}
              >
                <XMarkIcon className="btn-icon" />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="modal-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="id">Incident ID</label>
                  <input
                    type="text"
                    id="id"
                    name="id"
                    value={newIncident.id}
                    onChange={handleInputChange}
                    placeholder="2024-XXX"
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Incident Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={newIncident.name}
                    onChange={handleInputChange}
                    placeholder="Brief description"
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="type">Type</label>
                  <select
                    id="type"
                    name="type"
                    value={newIncident.type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Medical Emergency">Medical Emergency</option>
                    <option value="Traffic Accident">Traffic Accident</option>
                    <option value="Structure Fire">Structure Fire</option>
                    <option value="Hazmat">Hazmat</option>
                    <option value="Rescue">Rescue</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    value={newIncident.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Active">Active</option>
                    <option value="En Route">En Route</option>
                    <option value="On Scene">On Scene</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={newIncident.location}
                  onChange={handleInputChange}
                  placeholder="Address or location description"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="startTime">Start Time</label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  value={newIncident.startTime}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={newIncident.notes}
                  onChange={handleInputChange}
                  placeholder="Additional incident details..."
                  rows="4"
                />
              </div>

              <div className="modal-footer">
                <button 
                  type="button" 
                  className="cancel-btn"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="submit-btn"
                >
                  Create Incident
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Incidents;