import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function PersonalInformation() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    textEmail: '',
    carrier: 'verizon',
    phone: '(555) 123-4567',
    position: 'Incident Commander',
    department: 'Fire Department',
    idNumber: 'ID12345',
    organization: 'City Emergency Services'
  });

  const carriers = [
    { value: 'verizon', label: 'Verizon', domain: 'vtext.com' },
    { value: 'att', label: 'AT&T', domain: 'txt.att.net' },
    { value: 'tmobile', label: 'T-Mobile', domain: 'tmomail.net' },
    { value: 'sprint', label: 'Sprint', domain: 'messaging.sprintpcs.com' },
    { value: 'boost', label: 'Boost Mobile', domain: 'sms.myboostmobile.com' },
    { value: 'cricket', label: 'Cricket', domain: 'sms.cricketwireless.net' },
    { value: 'uscellular', label: 'US Cellular', domain: 'email.uscc.net' },
    { value: 'other', label: 'Other', domain: '' }
  ];

  // Function to format phone number
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  };

  // Function to generate text email
  const generateTextEmail = (phone, carrier) => {
    const phoneDigits = phone.replace(/\D/g, '');
    if (phoneDigits.length === 10) {
      const selectedCarrier = carriers.find(c => c.value === carrier);
      if (selectedCarrier && selectedCarrier.value !== 'other') {
        return `${phoneDigits}@${selectedCarrier.domain}`;
      }
    }
    return '';
  };

  // Handle phone number change
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormData(prev => ({
      ...prev,
      phone: formattedPhone,
      textEmail: generateTextEmail(formattedPhone, prev.carrier)
    }));
  };

  // Handle carrier change
  const handleCarrierChange = (e) => {
    const newCarrier = e.target.value;
    setFormData(prev => ({
      ...prev,
      carrier: newCarrier,
      textEmail: generateTextEmail(prev.phone, newCarrier)
    }));
  };

  // Initial text email generation
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      textEmail: generateTextEmail(prev.phone, prev.carrier)
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Personal Information</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="form-row">
            <div className="form-group half">
              <label>First Name</label>
              <input
                type="text"
                value={formData.firstName}
                onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                className="compact-input"
              />
            </div>
            <div className="form-group half">
              <label>Last Name</label>
              <input
                type="text"
                value={formData.lastName}
                onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                className="compact-input"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group half">
              <label>Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                className="compact-input"
              />
            </div>
            <div className="form-group half">
              <label>Phone</label>
              <input
                type="tel"
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="(555) 123-4567"
                className="compact-input"
                maxLength="14"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group half">
              <label>Cellphone Carrier</label>
              <select
                value={formData.carrier}
                onChange={handleCarrierChange}
                className="compact-input"
              >
                {carriers.map(carrier => (
                  <option key={carrier.value} value={carrier.value}>
                    {carrier.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group half">
              <label>Text Email Address</label>
              <input
                type="email"
                value={formData.textEmail}
                onChange={(e) => setFormData({...formData, textEmail: e.target.value})}
                className="compact-input"
                placeholder="Generated from phone and carrier"
                readOnly={formData.carrier !== 'other'}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group half">
              <label>Position</label>
              <input
                type="text"
                value={formData.position}
                onChange={(e) => setFormData({...formData, position: e.target.value})}
                className="compact-input"
                placeholder="Enter your position"
              />
            </div>
            <div className="form-group half">
              <label>Department</label>
              <input
                type="text"
                value={formData.department}
                onChange={(e) => setFormData({...formData, department: e.target.value})}
                className="compact-input"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group half">
              <label>Organization</label>
              <input
                type="text"
                value={formData.organization}
                onChange={(e) => setFormData({...formData, organization: e.target.value})}
                className="compact-input"
              />
            </div>
            <div className="form-group half">
              <label>ID Number</label>
              <input
                type="text"
                value={formData.idNumber}
                onChange={(e) => setFormData({...formData, idNumber: e.target.value})}
                className="compact-input"
              />
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Changes
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PersonalInformation; 