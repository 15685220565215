import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ChartBarIcon,
  TruckIcon,
  ExclamationTriangleIcon,
  Cog6ToothIcon,
  ShieldCheckIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';

export default function Sidebar() {
  const location = useLocation();
  const [isAdmin] = React.useState(true);

  const isActive = (path) => {
    return location.pathname === path;
  };

  // Define icon colors using our theme variables
  const iconColors = {
    dashboard: '#228be6',    // Blue
    resources: '#40c057',    // Green
    incidents: '#fa5252',    // Red
    settings: '#fab005',     // Yellow
    communications: '#be4bdb', // Purple (previously profile)
    admin: '#e64980'         // Pink
  };

  return (
    <div className="sidebar">
      <div className="logo">
        <h1>MICS</h1>
      </div>
      <nav className="nav-menu">
        <Link to="/" className={`nav-item ${isActive('/') ? 'active' : ''}`}>
          <ChartBarIcon className="nav-icon" style={{ color: iconColors.dashboard }} />
          <span>Dashboard</span>
        </Link>
        <Link to="/resources" className={`nav-item ${isActive('/resources') ? 'active' : ''}`}>
          <TruckIcon className="nav-icon" style={{ color: iconColors.resources }} />
          <span>Resources</span>
        </Link>
        <Link to="/incidents" className={`nav-item ${isActive('/incidents') ? 'active' : ''}`}>
          <ExclamationTriangleIcon className="nav-icon" style={{ color: iconColors.incidents }} />
          <span>Incidents</span>
        </Link>
        <Link to="/communications" className={`nav-item ${isActive('/communications') ? 'active' : ''}`}>
          <ChatBubbleLeftRightIcon className="nav-icon" style={{ color: iconColors.communications }} />
          <span>Communications</span>
        </Link>
        <Link to="/settings" className={`nav-item ${isActive('/settings') ? 'active' : ''}`}>
          <Cog6ToothIcon className="nav-icon" style={{ color: iconColors.settings }} />
          <span>Settings</span>
        </Link>
        {isAdmin && (
          <Link to="/admin" className={`nav-item ${isActive('/admin') ? 'active' : ''}`}>
            <ShieldCheckIcon className="nav-icon" style={{ color: iconColors.admin }} />
            <span>Admin</span>
          </Link>
        )}
      </nav>
    </div>
  );
}
