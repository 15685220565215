import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function Communications() {
  const navigate = useNavigate();
  const [communicationSettings, setCommunicationSettings] = useState({
    defaultChannel: 'radio',
    autoResponses: true,
    broadcastEnabled: true,
    statusUpdates: true,
    radioChannels: {
      primary: true,
      secondary: true,
      emergency: true
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Communication settings saved:', communicationSettings);
    // TODO: Save to backend
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Communications</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="preferences-section">
            <h3>General Settings</h3>
            
            <div className="form-group">
              <label>Default Communication Channel</label>
              <select
                value={communicationSettings.defaultChannel}
                onChange={(e) => setCommunicationSettings({
                  ...communicationSettings,
                  defaultChannel: e.target.value
                })}
                className="compact-input"
              >
                <option value="radio">Radio</option>
                <option value="message">Message</option>
                <option value="phone">Phone</option>
              </select>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.autoResponses}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    autoResponses: e.target.checked
                  })}
                />
                Enable Auto-Responses
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.broadcastEnabled}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    broadcastEnabled: e.target.checked
                  })}
                />
                Enable Broadcast Messages
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.statusUpdates}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    statusUpdates: e.target.checked
                  })}
                />
                Enable Status Updates
              </label>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Radio Channels</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.radioChannels.primary}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    radioChannels: {
                      ...communicationSettings.radioChannels,
                      primary: e.target.checked
                    }
                  })}
                />
                Primary Channel
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.radioChannels.secondary}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    radioChannels: {
                      ...communicationSettings.radioChannels,
                      secondary: e.target.checked
                    }
                  })}
                />
                Secondary Channel
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={communicationSettings.radioChannels.emergency}
                  onChange={(e) => setCommunicationSettings({
                    ...communicationSettings,
                    radioChannels: {
                      ...communicationSettings.radioChannels,
                      emergency: e.target.checked
                    }
                  })}
                />
                Emergency Channel
              </label>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Settings
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Communications; 