import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  BellIcon,
  EnvelopeIcon,
  CloudIcon,
  ShieldCheckIcon,
  ClockIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

function SystemSettings() {
  const navigate = useNavigate();
  const [saveStatus, setSaveStatus] = useState('');
  const [settings, setSettings] = useState({
    notifications: {
      enableEmailAlerts: true,
      enablePushNotifications: true,
      alertPriority: 'all',
      digestFrequency: 'daily'
    },
    email: {
      smtpServer: 'smtp.example.com',
      smtpPort: '587',
      senderEmail: 'alerts@example.com',
      enableSSL: true
    },
    backup: {
      autoBackup: true,
      backupFrequency: 'daily',
      retentionPeriod: '30',
      storageLocation: 'cloud'
    },
    security: {
      sessionTimeout: '30',
      maxLoginAttempts: '3',
      passwordExpiry: '90',
      mfaEnabled: true
    },
    system: {
      timezone: 'UTC',
      dateFormat: 'MM/DD/YYYY',
      timeFormat: '24h',
      language: 'en'
    }
  });

  const handleSettingChange = (category, setting, value) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: value
      }
    }));
  };

  const handleSave = () => {
    setSaveStatus('saving');
    // Simulate API call
    setTimeout(() => {
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus(''), 2000);
    }, 1000);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/admin')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Admin
        </button>
        <div className="header-content">
          <div className="header-title">
            <ClockIcon className="header-icon" style={{ color: '#40c057' }} />
            <div>
              <h1>System Settings</h1>
              <p>Configure system-wide settings and preferences</p>
            </div>
          </div>
          <button 
            className="save-button"
            onClick={handleSave}
            disabled={saveStatus === 'saving'}
            style={{ 
              backgroundColor: saveStatus === 'saved' ? '#40c05720' : '#40c05710',
              color: '#40c057',
              border: '1px solid #40c057'
            }}
          >
            {saveStatus === 'saving' ? 'Saving...' : 
             saveStatus === 'saved' ? (
               <>
                 <CheckIcon width={20} height={20} />
                 Saved
               </>
             ) : 'Save Changes'}
          </button>
        </div>
      </div>

      <div className="settings-grid">
        <div className="settings-card">
          <div className="settings-card-header">
            <BellIcon className="settings-icon" />
            <h3>Notifications</h3>
          </div>
          <div className="settings-card-content">
            <div className="setting-item">
              <label>Email Alerts</label>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={settings.notifications.enableEmailAlerts}
                  onChange={(e) => handleSettingChange('notifications', 'enableEmailAlerts', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <label>Push Notifications</label>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={settings.notifications.enablePushNotifications}
                  onChange={(e) => handleSettingChange('notifications', 'enablePushNotifications', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <label>Alert Priority</label>
              <select
                value={settings.notifications.alertPriority}
                onChange={(e) => handleSettingChange('notifications', 'alertPriority', e.target.value)}
              >
                <option value="all">All Priorities</option>
                <option value="high">High Only</option>
                <option value="critical">Critical Only</option>
              </select>
            </div>
            <div className="setting-item">
              <label>Digest Frequency</label>
              <select
                value={settings.notifications.digestFrequency}
                onChange={(e) => handleSettingChange('notifications', 'digestFrequency', e.target.value)}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
        </div>

        <div className="settings-card">
          <div className="settings-card-header">
            <EnvelopeIcon className="settings-icon" />
            <h3>Email Configuration</h3>
          </div>
          <div className="settings-card-content">
            <div className="setting-item">
              <label>SMTP Server</label>
              <input
                type="text"
                value={settings.email.smtpServer}
                onChange={(e) => handleSettingChange('email', 'smtpServer', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>SMTP Port</label>
              <input
                type="text"
                value={settings.email.smtpPort}
                onChange={(e) => handleSettingChange('email', 'smtpPort', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Sender Email</label>
              <input
                type="email"
                value={settings.email.senderEmail}
                onChange={(e) => handleSettingChange('email', 'senderEmail', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Enable SSL</label>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={settings.email.enableSSL}
                  onChange={(e) => handleSettingChange('email', 'enableSSL', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="settings-card">
          <div className="settings-card-header">
            <CloudIcon className="settings-icon" />
            <h3>Backup & Storage</h3>
          </div>
          <div className="settings-card-content">
            <div className="setting-item">
              <label>Auto Backup</label>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={settings.backup.autoBackup}
                  onChange={(e) => handleSettingChange('backup', 'autoBackup', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            <div className="setting-item">
              <label>Backup Frequency</label>
              <select
                value={settings.backup.backupFrequency}
                onChange={(e) => handleSettingChange('backup', 'backupFrequency', e.target.value)}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
            <div className="setting-item">
              <label>Retention Period (days)</label>
              <input
                type="number"
                value={settings.backup.retentionPeriod}
                onChange={(e) => handleSettingChange('backup', 'retentionPeriod', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Storage Location</label>
              <select
                value={settings.backup.storageLocation}
                onChange={(e) => handleSettingChange('backup', 'storageLocation', e.target.value)}
              >
                <option value="local">Local Storage</option>
                <option value="cloud">Cloud Storage</option>
              </select>
            </div>
          </div>
        </div>

        <div className="settings-card">
          <div className="settings-card-header">
            <ShieldCheckIcon className="settings-icon" />
            <h3>Security</h3>
          </div>
          <div className="settings-card-content">
            <div className="setting-item">
              <label>Session Timeout (minutes)</label>
              <input
                type="number"
                value={settings.security.sessionTimeout}
                onChange={(e) => handleSettingChange('security', 'sessionTimeout', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Max Login Attempts</label>
              <input
                type="number"
                value={settings.security.maxLoginAttempts}
                onChange={(e) => handleSettingChange('security', 'maxLoginAttempts', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Password Expiry (days)</label>
              <input
                type="number"
                value={settings.security.passwordExpiry}
                onChange={(e) => handleSettingChange('security', 'passwordExpiry', e.target.value)}
              />
            </div>
            <div className="setting-item">
              <label>Enable MFA</label>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={settings.security.mfaEnabled}
                  onChange={(e) => handleSettingChange('security', 'mfaEnabled', e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="settings-card">
          <div className="settings-card-header">
            <ClockIcon className="settings-icon" />
            <h3>System Preferences</h3>
          </div>
          <div className="settings-card-content">
            <div className="setting-item">
              <label>Timezone</label>
              <select
                value={settings.system.timezone}
                onChange={(e) => handleSettingChange('system', 'timezone', e.target.value)}
              >
                <option value="UTC">UTC</option>
                <option value="EST">Eastern Time</option>
                <option value="CST">Central Time</option>
                <option value="PST">Pacific Time</option>
              </select>
            </div>
            <div className="setting-item">
              <label>Date Format</label>
              <select
                value={settings.system.dateFormat}
                onChange={(e) => handleSettingChange('system', 'dateFormat', e.target.value)}
              >
                <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              </select>
            </div>
            <div className="setting-item">
              <label>Time Format</label>
              <select
                value={settings.system.timeFormat}
                onChange={(e) => handleSettingChange('system', 'timeFormat', e.target.value)}
              >
                <option value="12h">12-hour</option>
                <option value="24h">24-hour</option>
              </select>
            </div>
            <div className="setting-item">
              <label>Language</label>
              <select
                value={settings.system.language}
                onChange={(e) => handleSettingChange('system', 'language', e.target.value)}
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemSettings; 