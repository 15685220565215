import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function EditResource() {
  const { id } = useParams();
  const [resource, setResource] = useState({
    id: id,
    name: 'Engine 5',
    type: 'Fire Engine',
    status: 'Available',
    assignment: 'None',
    location: 'Station 1',
    crew: 4,
    capabilities: ['Water Tank', 'Ladder', 'Fire Suppression'],
    notes: []
  });

  const [newNote, setNewNote] = useState('');

  const addNote = (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    const note = {
      id: Date.now(),
      text: newNote,
      timestamp: new Date().toISOString(),
      initials: 'JS' // Should come from user context
    };

    setResource(prev => ({
      ...prev,
      notes: [note, ...prev.notes]
    }));
    setNewNote('');
  };

  return (
    <div className="edit-resource-page">
      <div className="page-header">
        <h1>Edit Resource</h1>
        <div className="header-actions">
          <span className="resource-id">Resource #{id}</span>
          <Link to="/resources" className="nav-link">
            Back to Resources List
          </Link>
        </div>
      </div>

      <div className="resource-detail-container">
        <div className="resource-info-section">
          <h2>Resource Information</h2>
          <div className="detail-grid">
            <div className="detail-item">
              <label>Name</label>
              <input
                type="text"
                value={resource.name}
                onChange={(e) => setResource({...resource, name: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Type</label>
              <select
                value={resource.type}
                onChange={(e) => setResource({...resource, type: e.target.value})}
              >
                <option value="Fire Engine">Fire Engine</option>
                <option value="Medical Transport">Medical Transport</option>
                <option value="Command Vehicle">Command Vehicle</option>
                <option value="Rescue Unit">Rescue Unit</option>
                <option value="Support Vehicle">Support Vehicle</option>
              </select>
            </div>

            <div className="detail-item">
              <label>Status</label>
              <select
                value={resource.status}
                onChange={(e) => setResource({...resource, status: e.target.value})}
              >
                <option value="Available">Available</option>
                <option value="In Service">In Service</option>
                <option value="Out of Service">Out of Service</option>
                <option value="En Route">En Route</option>
                <option value="On Scene">On Scene</option>
              </select>
            </div>

            <div className="detail-item">
              <label>Current Assignment</label>
              <input
                type="text"
                value={resource.assignment}
                onChange={(e) => setResource({...resource, assignment: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Location</label>
              <input
                type="text"
                value={resource.location}
                onChange={(e) => setResource({...resource, location: e.target.value})}
              />
            </div>

            <div className="detail-item">
              <label>Crew Size</label>
              <input
                type="number"
                value={resource.crew}
                onChange={(e) => setResource({...resource, crew: parseInt(e.target.value) || 0})}
                min="0"
                max="20"
              />
            </div>

            <div className="detail-item">
              <label>Capabilities</label>
              <div className="capabilities-list">
                {resource.capabilities.map((capability, index) => (
                  <span key={index} className="capability-badge">
                    {capability}
                    <button
                      className="remove-capability-btn"
                      onClick={() => setResource(prev => ({
                        ...prev,
                        capabilities: prev.capabilities.filter((_, i) => i !== index)
                      }))}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="resource-notes-section">
          <h2>Notes</h2>
          <form onSubmit={addNote} className="note-form">
            <div className="note-input-container">
              <input
                type="text"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Add a note..."
                className="note-input"
              />
              <button type="submit" className="add-note-btn">
                Add Note
              </button>
            </div>
          </form>

          <div className="notes-list">
            {resource.notes.map(note => (
              <div key={note.id} className="note-item">
                <div className="note-header">
                  <span className="note-time">
                    {new Date(note.timestamp).toLocaleTimeString()}
                  </span>
                  <span className="note-initials">{note.initials}</span>
                </div>
                <div className="note-text">{note.text}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="form-actions">
          <button className="submit-btn">Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default EditResource; 