import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

function ConfigurationPage({ title, icon: Icon, children }) {
  return (
    <div className="config-page">
      <div className="page-header">
        <div className="header-title">
          <h1>
            {Icon && <Icon className="header-icon" />}
            {title}
          </h1>
        </div>
        <div className="header-actions">
          <Link to="/settings" className="back-btn">
            <ChevronLeftIcon className="btn-icon" />
            Back to Settings
          </Link>
        </div>
      </div>
      <div className="config-content">
        {children}
      </div>
    </div>
  );
}

export default ConfigurationPage; 