import { createContext, useState, useContext, useEffect } from 'react';

const ListsContext = createContext();

export function ListsProvider({ children }) {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = 'https://mics-backend-ladli6w7v-emsics.vercel.app';

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = async () => {
    try {
      setLoading(true);
      console.log('Fetching lists from:', `${API_URL}/api/lists`);
      
      const response = await fetch(`${API_URL}/api/lists`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Received lists:', data);
      setLists(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching lists:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const addList = async (name) => {
    try {
      const newList = {
        id: Date.now().toString(),
        name,
        values: []
      };
      setLists(prev => [...prev, newList]);
      return newList;
    } catch (err) {
      console.error('Error adding list:', err);
      throw err;
    }
  };

  const addValue = async (listId, text) => {
    try {
      const newValue = {
        id: Date.now().toString(),
        text
      };
      
      setLists(prev => prev.map(list => {
        if (list.id === listId) {
          return {
            ...list,
            values: [...list.values, newValue]
          };
        }
        return list;
      }));
      
      return newValue;
    } catch (err) {
      console.error('Error adding value:', err);
      throw err;
    }
  };

  const deleteValue = async (listId, valueId) => {
    try {
      setLists(prev => prev.map(list => {
        if (list.id === listId) {
          return {
            ...list,
            values: list.values.filter(v => v.id !== valueId)
          };
        }
        return list;
      }));
    } catch (err) {
      console.error('Error deleting value:', err);
      throw err;
    }
  };

  return (
    <ListsContext.Provider value={{
      lists,
      loading,
      error,
      addList,
      addValue,
      deleteValue,
      refreshLists: fetchLists
    }}>
      {children}
    </ListsContext.Provider>
  );
}

export function useLists() {
  const context = useContext(ListsContext);
  if (!context) {
    throw new Error('useLists must be used within a ListsProvider');
  }
  return context;
} 