import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function AlertSettings() {
  const navigate = useNavigate();
  const [alertSettings, setAlertSettings] = useState({
    emergencyAlerts: true,
    patientUpdates: true,
    statusChanges: true,
    soundEnabled: true,
    soundVolume: 80,
    alertPriority: 'high',
    alertDuration: '15'
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Alert settings saved:', alertSettings);
    // TODO: Save to backend
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/settings')}>
          <ArrowLeftIcon width={20} height={20} />
          Back to Settings
        </button>
        <h1>Alert Settings</h1>
      </div>

      <div className="detail-card">
        <form onSubmit={handleSubmit} className="compact-form">
          <div className="preferences-section">
            <h3>Alert Types</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={alertSettings.emergencyAlerts}
                  onChange={(e) => setAlertSettings({
                    ...alertSettings,
                    emergencyAlerts: e.target.checked
                  })}
                />
                Emergency Alerts
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={alertSettings.patientUpdates}
                  onChange={(e) => setAlertSettings({
                    ...alertSettings,
                    patientUpdates: e.target.checked
                  })}
                />
                Patient Updates
              </label>
            </div>

            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={alertSettings.statusChanges}
                  onChange={(e) => setAlertSettings({
                    ...alertSettings,
                    statusChanges: e.target.checked
                  })}
                />
                Status Changes
              </label>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Sound Settings</h3>
            
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={alertSettings.soundEnabled}
                  onChange={(e) => setAlertSettings({
                    ...alertSettings,
                    soundEnabled: e.target.checked
                  })}
                />
                Enable Alert Sounds
              </label>
            </div>

            <div className="form-group">
              <label>Sound Volume</label>
              <input
                type="range"
                min="0"
                max="100"
                value={alertSettings.soundVolume}
                onChange={(e) => setAlertSettings({
                  ...alertSettings,
                  soundVolume: e.target.value
                })}
                className="volume-slider"
              />
              <span className="volume-value">{alertSettings.soundVolume}%</span>
            </div>
          </div>

          <div className="preferences-section">
            <h3>Alert Configuration</h3>
            
            <div className="form-group">
              <label>Alert Priority</label>
              <select
                value={alertSettings.alertPriority}
                onChange={(e) => setAlertSettings({
                  ...alertSettings,
                  alertPriority: e.target.value
                })}
                className="compact-input"
              >
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>

            <div className="form-group">
              <label>Alert Duration (seconds)</label>
              <select
                value={alertSettings.alertDuration}
                onChange={(e) => setAlertSettings({
                  ...alertSettings,
                  alertDuration: e.target.value
                })}
                className="compact-input"
              >
                <option value="5">5 seconds</option>
                <option value="10">10 seconds</option>
                <option value="15">15 seconds</option>
                <option value="30">30 seconds</option>
              </select>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="save-btn">
              Save Settings
            </button>
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => navigate('/settings')}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AlertSettings; 