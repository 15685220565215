import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SwatchIcon } from '@heroicons/react/24/outline';
import ConfigurationPage from '../../components/ConfigurationPage';

function Theme() {
  const [theme, setTheme] = useState('light');
  const [fontSize, setFontSize] = useState('medium');

  return (
    <ConfigurationPage title="Theme Settings" icon={SwatchIcon}>
      <div className="config-form">
        <div className="form-section">
          <h2>Appearance</h2>
          <div className="form-group">
            <label>Theme Mode</label>
            <div className="radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  name="theme"
                  value="light"
                  checked={theme === 'light'}
                  onChange={(e) => setTheme(e.target.value)}
                />
                Light
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="theme"
                  value="dark"
                  checked={theme === 'dark'}
                  onChange={(e) => setTheme(e.target.value)}
                />
                Dark
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="theme"
                  value="system"
                  checked={theme === 'system'}
                  onChange={(e) => setTheme(e.target.value)}
                />
                System
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>Font Size</label>
            <select 
              value={fontSize} 
              onChange={(e) => setFontSize(e.target.value)}
              className="select-input"
            >
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
          </div>
        </div>
        <div className="form-actions">
          <Link to="/settings" className="cancel-btn">Cancel</Link>
          <button type="submit" className="submit-btn">Save Changes</button>
        </div>
      </div>
    </ConfigurationPage>
  );
}

export default Theme; 