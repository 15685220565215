import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BellIcon,
  ChatBubbleLeftRightIcon,
  ChevronRightIcon,
  UserCircleIcon,
  ShieldCheckIcon
} from '@heroicons/react/24/outline';

function Settings() {
  const navigate = useNavigate();

  const settingSections = [
    {
      title: 'Account',
      icon: <UserCircleIcon className="section-icon" />,
      color: '#228be6',
      items: [
        { 
          name: 'Personal Information', 
          description: 'Update your name, contact details, and profile picture',
          path: '/settings/personal-information'
        },
        { 
          name: 'Password & Security', 
          description: 'Change password and security preferences',
          path: '/settings/password-security'
        }
      ]
    },
    {
      title: 'System',
      icon: <ShieldCheckIcon className="section-icon" />,
      color: '#40c057',
      items: [
        { 
          name: 'Preferences', 
          description: 'Set your personal preferences and defaults',
          path: '/settings/preferences'
        },
        { 
          name: 'Communications', 
          description: 'Configure communication settings and defaults',
          path: '/settings/communications'
        }
      ]
    },
    {
      title: 'Notifications',
      icon: <BellIcon className="section-icon" />,
      color: '#fab005',
      items: [
        { 
          name: 'Alert Settings', 
          description: 'Configure incident and emergency alerts',
          path: '/settings/alerts'
        },
        { 
          name: 'Email Notifications', 
          description: 'Manage email notification preferences',
          path: '/settings/email-notifications'
        },
        { 
          name: 'Mobile Notifications', 
          description: 'Configure push notifications for mobile devices',
          path: '/settings/mobile-notifications'
        }
      ]
    }
  ];

  return (
    <div className="settings-page">
      <div className="page-header">
        <div className="header-content">
          <h1>Settings</h1>
          <p className="header-description">Configure your account and system preferences</p>
        </div>
      </div>

      <div className="settings-grid">
        {settingSections.map((section, index) => (
          <div key={index} className="settings-section">
            <div className="section-header" style={{ color: section.color }}>
              {React.cloneElement(section.icon, { style: { color: section.color } })}
              <h2>{section.title}</h2>
            </div>
            <div className="section-content">
              {section.items.map((item, itemIndex) => (
                <div 
                  key={itemIndex} 
                  className="setting-item"
                  onClick={() => navigate(item.path)}
                >
                  <div className="setting-info">
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                  </div>
                  <button className="setting-btn">
                    <ChevronRightIcon className="btn-icon" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Settings; 