import React, { useState, useCallback, useMemo } from 'react';
import { 
  PencilIcon, 
  CheckIcon, 
  XMarkIcon,
  PlusIcon,
  TrashIcon,
  DeleteIcon
} from '@heroicons/react/24/outline';
import '../styles/Lists.css';
import { useLists } from '../contexts/ListsContext';
import debounce from 'lodash/debounce';

export default function Lists() {
  const { lists, setLists } = useLists();
  const [selectedList, setSelectedList] = useState(null);

  const defaultColors = [
    '#fa5252', // red
    '#fd7e14', // orange
    '#fab005', // yellow
    '#40c057', // green
    '#228be6', // blue
    '#be4bdb', // purple
    '#495057', // dark gray
    '#868e96', // light gray
  ];

  const [editingItem, setEditingItem] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [isAddingNew, setIsAddingNew] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(null);

  const handleEdit = (listId, valueId) => {
    const list = lists.find(item => item.id === listId);
    const value = list.values.find(v => v.id === valueId);
    setEditingItem({ listId, valueId });
    setEditingValue(value.value);
  };

  const handleSave = (listId, valueId) => {
    setLists(lists.map(list => {
      if (list.id === listId) {
        return {
          ...list,
          values: list.values.map(v => 
            v.id === valueId ? { ...v, value: editingValue } : v
          )
        };
      }
      return list;
    }));
    setEditingItem(null);
    setEditingValue('');
  };

  const handleAddNew = (listId) => {
    setIsAddingNew(listId);
    setEditingValue('');
  };

  const handleSaveNew = (listId) => {
    if (!editingValue.trim()) {
      setIsAddingNew(null);
      return;
    }

    setLists(lists.map(list => {
      if (list.id === listId) {
        const newId = Math.max(...list.values.map(v => v.id)) + 1;
        return {
          ...list,
          values: [...list.values, { id: newId, value: editingValue, color: '#ced4da' }]
        };
      }
      return list;
    }));
    setIsAddingNew(null);
    setEditingValue('');
  };

  const handleDelete = useCallback((listId, valueId) => {
    setLists(prevLists => 
      prevLists.map(list => {
        if (list.id === listId) {
          return {
            ...list,
            values: list.values.filter(v => v.id !== valueId)
          };
        }
        return list;
      })
    );
  }, [setLists]);

  const debouncedDelete = useMemo(
    () => debounce(handleDelete, 300),
    [handleDelete]
  );

  const handleColorSelect = (listId, valueId, newColor) => {
    setLists(prevLists => 
      prevLists.map(list => 
        list.id === listId 
          ? {
              ...list,
              values: list.values.map(value =>
                value.id === valueId
                  ? { ...value, color: newColor }
                  : value
              )
            }
          : list
      )
    );
    setShowColorPicker(null);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <h1>System Lists</h1>
        <p>Manage system-wide lists and options</p>
      </div>

      <div className="lists-container">
        {lists.map(list => (
          <div key={list.id} className="detail-card">
            <div className="card-header">
              <h2>{list.name}</h2>
            </div>
            
            <table className="list-table">
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Color</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.values.map(value => (
                  <tr key={value.id} className="list-row">
                    <td>
                      {editingItem?.listId === list.id && 
                       editingItem?.valueId === value.id ? (
                        <input
                          type="text"
                          value={editingValue}
                          onChange={(e) => setEditingValue(e.target.value)}
                          className="edit-input"
                          autoFocus
                        />
                      ) : (
                        value.value
                      )}
                    </td>
                    <td>
                      <div className="color-picker-container">
                        <div 
                          className="color-indicator"
                          style={{ backgroundColor: value.color }}
                          onClick={() => setShowColorPicker({ listId: list.id, valueId: value.id })}
                        />
                        {showColorPicker?.listId === list.id && 
                         showColorPicker?.valueId === value.id && (
                          <div className="color-picker-popover">
                            <div 
                              className="color-picker-cover"
                              onClick={() => setShowColorPicker(null)}
                            />
                            <div className="color-chips">
                              {defaultColors.map((color, index) => (
                                <button
                                  key={index}
                                  className="color-chip"
                                  style={{ backgroundColor: color }}
                                  onClick={() => handleColorSelect(list.id, value.id, color)}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="action-buttons">
                        {editingItem?.listId === list.id && 
                         editingItem?.valueId === value.id ? (
                          <>
                            <button 
                              className="save-button"
                              onClick={() => handleSave(list.id, value.id)}
                            >
                              <CheckIcon className="icon" width={14} height={14} />
                            </button>
                            <button 
                              className="cancel-button"
                              onClick={() => setEditingItem(null)}
                            >
                              <XMarkIcon className="icon" width={14} height={14} />
                            </button>
                          </>
                        ) : (
                          <>
                            <button 
                              className="edit-button"
                              onClick={() => handleEdit(list.id, value.id)}
                            >
                              <PencilIcon className="icon" width={14} height={14} />
                            </button>
                            <button 
                              className="delete-button"
                              onClick={() => handleDelete(list.id, value.id)}
                              aria-label="Delete value"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                <tr className="new-row">
                  <td>
                    {isAddingNew === list.id ? (
                      <input
                        type="text"
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        className="edit-input"
                        placeholder="Enter new value..."
                        autoFocus
                      />
                    ) : (
                      <button 
                        className="add-inline-button"
                        onClick={() => handleAddNew(list.id)}
                      >
                        <PlusIcon className="icon" width={14} height={14} /> Add new
                      </button>
                    )}
                  </td>
                  <td></td>
                  <td>
                    {isAddingNew === list.id && (
                      <div className="action-buttons">
                        <button 
                          className="save-button"
                          onClick={() => handleSaveNew(list.id)}
                        >
                          <CheckIcon className="icon" width={14} height={14} />
                        </button>
                        <button 
                          className="cancel-button"
                          onClick={() => setIsAddingNew(null)}
                        >
                          <XMarkIcon className="icon" width={14} height={14} />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
} 
