import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Communications.css';

function Communications() {
  const navigate = useNavigate();

  const handleNewEmergencyAlert = () => {
    navigate('/communications/alerts');
  };

  const handleNewAnnouncement = () => {
    navigate('/communications/announcements');
  };

  // Sample combined data - replace with your actual data
  const communications = [
    { 
      id: 1, 
      type: 'alert',
      title: 'Severe Weather Warning', 
      priority: 'High', 
      author: 'System',
      timestamp: '2024-03-14 10:30' 
    },
    { 
      id: 2, 
      type: 'announcement',
      title: 'New Protocol Implementation', 
      priority: 'Normal', 
      author: 'Admin',
      timestamp: '2024-03-14 09:45' 
    },
    { 
      id: 3, 
      type: 'alert',
      title: 'Emergency Response Required', 
      priority: 'High', 
      author: 'Emergency Resp.',
      timestamp: '2024-03-14 08:45' 
    },
    // Add more items as needed
  ];

  return (
    <div className="communications-container">
      <h1 className="page-title">Communications</h1>

      {/* Stats Overview */}
      <div className="stats-grid">
        <div className="stat-card">
          <h2>Active Alerts</h2>
          <span className="stat-number alert">3</span>
        </div>
        <div className="stat-card">
          <h2>Recent Announcements</h2>
          <span className="stat-number warning">12</span>
        </div>
      </div>

      {/* Action Buttons - Updated with onClick handlers */}
      <div className="action-buttons">
        <button 
          onClick={handleNewEmergencyAlert} 
          className="action-button emergency"
        >
          <i className="fas fa-exclamation-triangle"></i>
          New Emergency Alert
        </button>
        <button 
          onClick={handleNewAnnouncement} 
          className="action-button announcement"
        >
          <i className="fas fa-bullhorn"></i>
          New Announcement
        </button>
      </div>

      {/* Combined Communications Table */}
      <div className="table-section">
        <div className="table-header">
          <h2>Recent Communications</h2>
        </div>
        <table className="data-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Title</th>
              <th>Priority</th>
              <th>Author</th>
              <th>Timestamp</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {communications.map(comm => (
              <tr key={comm.id} className={comm.type}>
                <td>
                  <span className={`type-badge ${comm.type}`}>
                    <i className={`fas ${comm.type === 'alert' ? 'fa-exclamation-triangle' : 'fa-bullhorn'}`}></i>
                    {comm.type === 'alert' ? 'Alert' : 'Announcement'}
                  </span>
                </td>
                <td>{comm.title}</td>
                <td>
                  <span className={`priority ${comm.priority.toLowerCase()}`}>
                    {comm.priority}
                  </span>
                </td>
                <td>{comm.author}</td>
                <td>{comm.timestamp}</td>
                <td>
                  <button className="table-action-btn" title="Edit">
                    <i className="fas fa-edit"></i>
                  </button>
                  <button className="table-action-btn" title="Delete">
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Communications; 